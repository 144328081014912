import { GetterTree } from 'vuex';
import { NavigationState } from '@/store/navigation/index';
import {
  Link,
  Navigation,
  NavigationItem,
} from '../../../types/contentful-api';
import { buildNavigationTree } from '@/helpers/navigation-tree';
import { NavigationTree } from '@/store/navigation/models/NavigationTree';
import { NavigationTreeItem } from '@/store/navigation/models/NavigationTreeItem';
import { searchTreeItemByPath } from '@/helpers/search-navigation-tree';
import { NavbarTransparentElement } from '@/models/navigation/NavbarTypes';

export const navigationGetters: GetterTree<NavigationState, any> = {
  navigationItems: (state: NavigationState): NavigationItem[] => {
    return state.navigationItems.data;
  },
  navigationItem:
    (state: NavigationState, getters) =>
    (menuSelect: string): NavigationItem | undefined => {
      return getters['navigationItems'].find(
        (navigationItem: NavigationItem) =>
          navigationItem?.link?.link === menuSelect
      );
    },
  navigations: (state: NavigationState): Navigation[] => {
    return state.navigations.data;
  },
  navigation:
    (state: NavigationState) =>
    (menuSelect: string): Navigation | undefined => {
      return state.navigations.data.find(
        (navigation: Navigation) => navigation.menuSelect === menuSelect
      );
    },
  navigationTree:
    (state: NavigationState, getters, rootState) =>
    (menuSelect: string): NavigationTree => {
      const locale = rootState.app.ui.locale;
      const navigation: Navigation = getters['navigation'](menuSelect);
      const navigationItems: NavigationItem[] = getters['navigationItems'];
      if (!navigation) {
        return [];
      }
      return buildNavigationTree(navigation, navigationItems, locale);
    },
  navigationTreeItem:
    () =>
    (menuSelect: string, tree: NavigationTree): NavigationTreeItem | null => {
      return searchTreeItemByPath(menuSelect, tree);
    },
  links: (state: NavigationState): Link[] => {
    return state.links.data;
  },
  linkBySlug:
    (state: NavigationState, getters) =>
    (slug: string): Link | undefined => {
      return getters['links'].find(
        (link: Link) => link && link.link && link.link === `/${slug}`
      );
    },
  currentLink: (state: NavigationState): Link | null => {
    return state.currentLink;
  },
  pageIdBySlug:
    (state: NavigationState) =>
    (payload: string): string | null => {
      const navigationItems = state.navigationItems.data;
      const navigationItem = navigationItems.find(
        (currentItem: NavigationItem) =>
          currentItem?.link?.link === `/${payload}`
      );
      return navigationItem && navigationItem.link && navigationItem.link.page
        ? navigationItem.link.page.sys.id
        : null;
    },
  isLocationSwitcherOpen: (state: NavigationState) => (): boolean => {
    return state.locationSwitcherExpanded;
  },
  isNavbarHidden: (state: NavigationState): boolean => state.navbarHidden,
  navbarTransparentElement: (
    state: NavigationState
  ): NavbarTransparentElement => state.transparentElement,
};
