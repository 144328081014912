import { GetterTree } from 'vuex';
import { AppState } from '@/store/app/index';
import { PageHeaderType } from '@/store/app/models/PageHeaderType';
import { DKVerticalOverview } from '@/models/DKVerticalOverview';
import { VerticalOverviewFactory } from '@/models/factories/VerticalOverviewFactory';
import { DKStockPrice as DKStockPriceModel } from '@dormakaba/dormakaba-components/dist/types/lib/models/DKStockPrice';
import { StockPriceFactory } from '@/models/factories/StockPriceFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export const getters: GetterTree<AppState, any> = {
  isMenuOpen: (state: AppState): boolean => {
    return state.ui.isMenuOpen;
  },
  hasBackdrop: (state: AppState): boolean => {
    return state.ui.hasBackdrop;
  },
  headerType: (state: AppState): PageHeaderType | null => {
    return state.ui.headerType;
  },
  showFooterGroup: (state: AppState): boolean => {
    return state.ui.showFooterGroup;
  },
  locale: (state: AppState): string => {
    return state.ui.locale;
  },
  stockPrice: (
    state: AppState,
    getters,
    rootState
  ): DKStockPriceModel | null => {
    const locale = rootState.app.ui.locale;
    const data = state.footer.stockPrice;
    const stockPriceFactory = new StockPriceFactory(locale);
    let stockPrice: DKStockPriceModel | null = null;

    try {
      if (data) {
        stockPrice = stockPriceFactory.create(data);
      }
    } catch (e) {
      if (e instanceof PropertyRequiredError) {
        console.warn('Could not generate StockPrice');
        console.warn(e);
      } else {
        throw e;
      }
    }
    return stockPrice;
  },
  verticalOverview: (state: AppState): DKVerticalOverview | null => {
    const locale = state.ui.locale;
    let verticalOverview: DKVerticalOverview | null = null;

    if (state.verticalOverview) {
      const verticalOverviewFactory = new VerticalOverviewFactory(locale);
      try {
        verticalOverview = verticalOverviewFactory.create(
          state.verticalOverview
        );
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate VerticalOverview');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return verticalOverview;
  },
};
