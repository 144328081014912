import { Module } from 'vuex';
import { contentGetters } from '@/store/content/getters';
import { actions } from '@/store/content/actions';
import { mutations } from '@/store/content/mutations';
import { DKGenericPage } from '@/store/content/models/DKGenericPage';
import { DKReusablePage } from '@/store/content/models/DKReusablePage';
import { DKNewsDetailPage } from '@/store/content/models/DKNewsDetailPage';
import { DKGenericPageFilters } from '@/store/content/models/DKGenericPageFilters';
import { DKEventCollection } from '@/store/content/models/DKEventCollection';
import { DKReportCollection } from '@/store/content/models/DKReportCollection';
import { DKReportTopicCollection } from '@/store/content/models/DKReportTopicCollection';
import { DKLegalPage } from '@/store/content/models/DKLegalPage';
import { DKFormPage } from '@/store/content/models/DKFormPage';
import { DKPortfolioCollection } from '@/store/content/models/DKPortfolioCollection';
import { DKCorporateGovernancePage } from '@/store/content/models/DKCorporateGovernancePage';
import { GenericPageContentFragmentsItem } from '../../../types/contentful-api';
import { DKGeneralMeeting } from '@/store/content/models/DKGeneralMeeting';
import { DKHomePage } from '@/store/content/models/DKHomePage';
import { DKPageId } from '@/store/content/models/DKPageId';
import { DKCareerInsightPage } from '@/store/content/models/DKCareerInsightPage';
import { DKEqsNewsItemCategoryCollection } from '@/store/content/models/DKEqsNewsItemCategoryCollection';
import { DKCareerInsightPageCollection } from '@/store/content/models/DKCareerInsightPageCollection';
import { DKUseCasePage } from '@/store/content/models/DKUseCasePage';
import { DKUseCasePageCollection } from './models/DKUseCasePageCollection';
import { DKValuePromiseCollection } from '@/store/content/models/DKValuePromiseCollection';
import { DKEqsNewsCollection } from '@/store/content/models/DKEqsNewsCollection';
import { DKNewsPage } from '@/store/content/models/DKNewsPage';
import { DKEventStatus } from '@/models/DKEventOverview';

export interface ContentState {
  homePage: DKHomePage;
  homeSearchTerms: string[];
  genericPage: DKGenericPage;
  reusablePage: DKReusablePage;
  newsDetailPage: DKNewsDetailPage;
  events: DKEventCollection;
  genericPageFilters: DKGenericPageFilters;
  reportCollection: DKReportCollection;
  reportTopicCollection: DKReportTopicCollection;
  portfolioCollection: DKPortfolioCollection;
  legalPage: DKLegalPage;
  formPage: DKFormPage;
  useCasePageId: DKPageId;
  useCasePage: DKUseCasePage;
  useCasePageCollection: DKUseCasePageCollection;
  corporateGovernancePage: DKCorporateGovernancePage;
  careerInsightPageId: DKPageId;
  careerInsightPage: DKCareerInsightPage;
  careerInsightPageCollection: DKCareerInsightPageCollection;
  generalMeetings: DKGeneralMeeting;
  newsPage: DKNewsPage;
  eqsNewsItemCategoryCollection: DKEqsNewsItemCategoryCollection;
  eqsNewsCategoryFilter: string[];
  eqsNewsCollection: DKEqsNewsCollection;
  fragments: { [key: string]: GenericPageContentFragmentsItem };
  valuePromiseCollection: DKValuePromiseCollection;
}

const contentModule: Module<ContentState, any> = {
  state: () => ({
    homePage: {
      loading: false,
      data: null,
    },
    homeSearchTerms: [],
    genericPage: {
      loading: false,
      data: null,
    },
    reusablePage: {
      loading: false,
      data: null,
    },
    newsDetailPage: {
      loading: false,
      data: null,
    },
    careerInsightPageId: {
      loading: false,
      id: '',
    },
    careerInsightPage: {
      loading: false,
      data: null,
      backLink: null,
      backLinkHash: '',
    },
    careerInsightPageCollection: {
      loading: false,
      data: {
        items: [],
      },
    },
    events: {
      loading: false,
      data: {
        upcoming: [],
        totalUpcoming: 0,
        past: [],
        totalPast: 0,
      },
    },
    genericPageFilters: {
      eventToggleStatus: DKEventStatus.Upcoming,
      portfolioToggleStatus: 'products',
      eventCategory: null,
    },
    reportCollection: {
      loading: false,
      data: {
        items: [],
        link: null,
        linkHash: '',
        total: 0,
      },
    },
    reportTopicCollection: {
      loading: false,
      data: [],
    },
    legalPage: {
      loading: false,
      data: null,
    },
    formPage: {
      loading: false,
      data: null,
    },
    useCasePageId: {
      loading: false,
      id: '',
    },
    useCasePage: {
      loading: false,
      data: null,
    },
    useCasePageCollection: {
      loading: false,
      data: {
        items: [],
      },
    },
    corporateGovernancePage: {
      loading: false,
      data: null,
      backLink: null,
    },
    portfolioCollection: {
      loading: false,
      data: {
        productClusters: [],
        services: [],
      },
    },
    generalMeetings: {
      loading: false,
      data: [],
    },
    newsPage: {
      loading: false,
      data: null,
      backLink: null,
    },
    eqsNewsItemCategoryCollection: {
      loading: false,
      data: [],
    },
    eqsNewsCategoryFilter: [],
    eqsNewsCollection: {
      loading: false,
      data: {
        items: [],
        _eqs: {
          total_news: 0,
          total_pages: 0,
        },
      },
      newsLink: null,
    },
    fragments: {},
    valuePromiseCollection: {
      loading: false,
      data: {
        items: [],
      },
    },
  }),
  getters: contentGetters,
  actions,
  mutations,
  namespaced: true,
};

export default contentModule;
