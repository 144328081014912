import { VueGtmUseOptions } from '@gtm-support/vue-gtm';
import { Vue } from 'vue-facing-decorator';
import { GenericPageContentFragmentsItem } from '../../types/contentful-api';
import { getEnv } from '@/env';

export const getGoogleMapsKey = (): string | undefined => {
  return getEnv('GOOGLE_MAPS_KEY');
};

export const getGtmOptions = (): VueGtmUseOptions => {
  const gtmId = getEnv('GTM_ID') || '';
  const isProd = getEnv('CONTENTFUL_ENV') === 'master';

  /* More options in the package repo: https://github.com/gtm-support/vue-gtm */
  return {
    id: gtmId,
    compatibility: true,
    enabled: !!gtmId,
    debug: !isProd,
    loadScript: true,
    ignoredViews: [''],
    trackOnNextTick: false,
    defer: true,
  };
};

export const trackFragment = (
  event: MouseEvent,
  fragment: GenericPageContentFragmentsItem
): void => {
  if (!Vue.prototype.$gtm) {
    return;
  }

  if (!hasLink(event.target as HTMLElement)) {
    return;
  }

  const fragmentData = {
    event: 'click_fragment',
    fragment_id: fragment.sys.id,
    fragment_type: camelCasetoSnakeCase(fragment.__typename || ''),
    fragment_short_title: (fragment as any).shortTitle || '',
  };

  Vue.prototype.$gtm.dataLayer().push(fragmentData);

  if (getGtmOptions().debug) {
    console.log('[GTM-Datalayer]: Dispatching event', fragmentData);
  }
};

export const trackView = (viewName: string, pageTitle: string): void => {
  if (!Vue.prototype.$gtm) {
    return;
  }

  setTimeout(() => {
    const viewData = {
      event: 'content_view',
      'content-view-name': viewName,
      'content-name': viewName,
      page_title: pageTitle,
      page_location: window.location.href,
      page_path: pagePath(),
      page_referrer: pageReferrer(),
    };

    Vue.prototype.$gtm.dataLayer().push(viewData);

    if (getGtmOptions().debug) {
      console.log('[GTM-Datalayer]: Dispatching event', viewData);
    }
  });
};

export const trackInPage = (title: string, fragment: string): void => {
  if (!Vue.prototype.$gtm) {
    return;
  }

  const inPageData = {
    event: 'click_in_page_nav',
    nav_title: title,
    nav_fragment: fragment,
  };

  Vue.prototype.$gtm.dataLayer().push(inPageData);

  if (getGtmOptions().debug) {
    console.log('[GTM-Datalayer]: Dispatching event', inPageData);
  }
};

export const camelCasetoSnakeCase = (value: string): string => {
  return value.replace(/[A-Z]/g, (letter, index) => {
    return index == 0 ? letter.toLowerCase() : '_' + letter.toLowerCase();
  });
};

const hasLink = (target: HTMLElement): boolean => {
  if (!target || target.tagName === 'SECTION') {
    return false;
  } else if (['A', 'BUTTON', 'INPUT'].includes(target.tagName)) {
    return true;
  }
  return hasLink(target.parentElement as HTMLElement);
};

const pagePath = (): string => {
  return window.location.href.replace(window.location.origin, '');
};

const pageReferrer = (): string => {
  const state = window.history.state;
  return state?.back
    ? `${window.location.origin}${state?.back}`
    : document.referrer;
};
