import { GetterTree } from 'vuex';
import { ContentState } from '@/store/content/index';
import {
  GenericPageContentFragmentsItem,
  Event,
  Report,
  ReportTopic,
  GeneralMeeting,
  HomePageContentFragmentsItem,
  NewsDetailPageContentFragmentsItem,
  CareerInsightPage,
  UseCase,
  ValuePromise,
} from '../../../types/contentful-api';
import { notEmpty } from '@/helpers/not-empty';
import { shuffleArray } from '@/helpers/shuffle';
import { hasId } from '@/helpers/has-id';
import { CareerInsightPageFactory } from '@/models/factories/CareerInsightPageFactory';
import { DKCareerInsightPage } from '@/models/DKCareerInsightPage';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { FormPageFactory } from '@/models/factories/FormPageFactory';
import { DKFormPage } from '@/models/DKFormPage';
import { DKGeneralMeeting } from '@/models/DKGeneralMeeting';
import { GeneralMeetingFactory } from '@/models/factories/GeneralMeetingFactory';
import { LegalPageFactory } from '@/models/factories/LegalPageFactory';
import { DKLegalPage } from '@/models/DKLegalPage';
import { GenericPageFactory } from '@/models/factories/GenericPageFactory';
import { ReusablePageFactory } from '@/models/factories/ReusablePageFactory';
import { DKGenericPage } from '@/models/DKGenericPage';
import { DKReusablePage } from '@/models/DKReusablePage';
import { DKNewsDetailPage } from '@/models/DKNewsDetailPage';
import { DKManagement } from '@/models/DKManagement';
import { ManagementFactory } from '@/models/factories/ManagementFactory';
import { EventFactory } from '@/models/factories/EventFactory';
import { DKEvent } from '@/models/DKEvent';
import { DKEventStatus } from '@/models/DKEventOverview';
import { EventCategory } from '@/models/EventCategory';
import { DKHomePage } from '@/models/DKHomePage';
import { HomePageFactory } from '@/models/factories/HomePageFactory';
import { DKLink } from '@/models/DKLink';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { DKReport } from '@/models/DKReport';
import { DKReportTopic } from '@/models/DKReportTopic';
import { ReportTopicFactory } from '@/models/factories/ReportTopicFactory';
import { ReportFactory } from '@/models/factories/ReportFactory';
import { DKUseCase } from '@/models/DKUseCase';
import { UseCaseFactory } from '@/models/factories/UseCaseFactory';
import { DKValuePromise } from '@/models/DKValuePromise';
import { ValuePromiseFactory } from '@/models/factories/ValuePromiseFactory';
import { EqsNewsItem, EqsNewsItemCategory } from '../../../types/eqs-types';
import { DKNewsResponse } from '@/models/DKNewsResponse';
import { EqsNewsResponseFactory } from '@/models/factories/EqsNewsResponseFactory';
import { NewsDetailPageFactory } from '@/models/factories/NewsDetailPageFactory';

export const contentGetters: GetterTree<ContentState, any> = {
  homePage: (state: ContentState, getters, rootState): DKHomePage | null => {
    const locale = rootState.app.ui.locale;
    let homePage: DKHomePage | null = null;

    if (state.homePage.data) {
      const data = state.homePage.data;
      const homePageFactory = new HomePageFactory(locale);

      if (data.contentFragmentsCollection?.items) {
        let items: HomePageContentFragmentsItem[] = data
          .contentFragmentsCollection.items as HomePageContentFragmentsItem[];
        items = items
          .map((fragment: HomePageContentFragmentsItem) => {
            const id = fragment?.sys?.id || null;
            return getters.fragmentById(id);
          })
          .filter(notEmpty)
          .filter(hasId);

        data.contentFragmentsCollection.items = items;
      }

      try {
        homePage = homePageFactory.create(data);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate HomePage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return homePage;
  },
  homeSearchTerms: (state: ContentState): string[] => {
    return shuffleArray(state.homeSearchTerms);
  },
  fragmentById:
    (state: ContentState) =>
    (id: string): GenericPageContentFragmentsItem | null => {
      return state.fragments[id] || null;
    },
  genericPage: (
    state: ContentState,
    getters,
    rootState
  ): DKGenericPage | null => {
    const locale = rootState.app.ui.locale;
    let genericPage: DKGenericPage | null = null;

    if (state.genericPage.data) {
      const data = state.genericPage.data;
      const genericPageFactory = new GenericPageFactory(locale);

      if (data.contentFragmentsCollection?.items) {
        let items: GenericPageContentFragmentsItem[] = data
          .contentFragmentsCollection
          .items as GenericPageContentFragmentsItem[];
        items = items
          .map((fragment: GenericPageContentFragmentsItem) => {
            const id = fragment?.sys?.id || null;
            return getters.fragmentById(id);
          })
          .filter(notEmpty)
          .filter(hasId);

        data.contentFragmentsCollection.items = items;
      }

      try {
        genericPage = genericPageFactory.create(data);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate GenericPage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return genericPage;
  },
  reusablePage: (state: ContentState, _, rootState): DKReusablePage | null => {
    const locale = rootState.app.ui.locale;
    let reusablePage: DKReusablePage | null = null;

    if (state.reusablePage.data) {
      const { data } = state.reusablePage;
      const reusablePageFactory = new ReusablePageFactory(locale);

      try {
        reusablePage = reusablePageFactory.create(data);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate GenericPage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return reusablePage;
  },
  newsDetailPage: (
    state: ContentState,
    getters,
    rootState
  ): DKNewsDetailPage | null => {
    const locale = rootState.app.ui.locale;
    let newsDetailPage: DKNewsDetailPage | null = null;
    if (state.newsDetailPage.data) {
      const data = state.newsDetailPage.data;
      const newsDetailPageFactory = new NewsDetailPageFactory(locale);

      if (data.contentFragmentsCollection?.items) {
        let items: NewsDetailPageContentFragmentsItem[] = data
          .contentFragmentsCollection
          .items as NewsDetailPageContentFragmentsItem[];

        items = items
          .map((fragment: NewsDetailPageContentFragmentsItem) => {
            const id = fragment?.sys?.id || null;
            return getters.fragmentById(id);
          })
          .filter(notEmpty)
          .filter(hasId);

        data.contentFragmentsCollection.items = items;
      }
      try {
        newsDetailPage = newsDetailPageFactory.create(data);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate NewsDetailPage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }
    return newsDetailPage;
  },
  careerInsightPageId: (state: ContentState): string => {
    return state.careerInsightPageId.id;
  },
  careerInsightPage: (
    state: ContentState,
    getters,
    rootState
  ): DKCareerInsightPage | null => {
    const locale = rootState.app.ui.locale;
    let careerInsightPage: DKCareerInsightPage | null = null;

    if (state.careerInsightPage.data) {
      const careerInsightPageFactory = new CareerInsightPageFactory(locale);
      try {
        careerInsightPage = careerInsightPageFactory.create(
          state.careerInsightPage.data
        );
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate CareerInsightPage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return careerInsightPage;
  },
  careerInsightBackLink: (
    state: ContentState,
    getters,
    rootState
  ): DKLink | null => {
    const locale = rootState.app.ui.locale;
    let link: DKLink | null = null;

    if (state.careerInsightPage.backLink) {
      const linkFactory = new LinkFactory(locale);

      try {
        link = linkFactory.create(state.careerInsightPage.backLink);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return link;
  },
  careerInsightBackLinkHash: (state: ContentState): string => {
    return state.careerInsightPage.backLinkHash;
  },
  reportCollection:
    (state: ContentState, getters, rootState) =>
    (preview = false): DKReport[] => {
      const locale = rootState.app.ui.locale;
      const reports: DKReport[] = [];

      if (state.reportCollection.data.items) {
        const reportFactory = new ReportFactory(locale);
        state.reportCollection.data.items.forEach((report: Report) => {
          try {
            reports.push(reportFactory.create(report));
          } catch (e) {
            if (e instanceof PropertyRequiredError) {
              console.warn('Could not generate Report');
              console.warn(e);
            } else {
              throw e;
            }
          }
        });
      }

      return preview ? reports.slice(0, 3) : reports;
    },
  hasMoreReports: (state: ContentState): boolean => {
    return (
      state.reportCollection.data.items.length <
      state.reportCollection.data.total
    );
  },
  reportTopicCollection: (
    state: ContentState,
    getters,
    rootState
  ): DKReportTopic[] => {
    const locale = rootState.app.ui.locale;
    const reportTopics: DKReportTopic[] = [];

    if (state.reportTopicCollection.data) {
      const reportTopicFactory = new ReportTopicFactory(locale);
      state.reportTopicCollection.data.forEach((reportTopic: ReportTopic) => {
        try {
          reportTopics.push(reportTopicFactory.create(reportTopic));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate ReportTopic');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    return reportTopics;
  },
  reportArchiveLink: (state: ContentState): string => {
    return state.reportCollection.data.link
      ? state.reportCollection.data.link.link || ''
      : '';
  },
  reportArchiveLinkHash: (state: ContentState): string => {
    return state.reportCollection.data.linkHash
      ? state.reportCollection.data.linkHash
      : '';
  },
  eventToggleStatus: (state: ContentState): DKEventStatus => {
    return state.genericPageFilters.eventToggleStatus;
  },
  eventCategory: (state: ContentState): EventCategory | null => {
    return state.genericPageFilters.eventCategory;
  },
  upcomingEvents: (state: ContentState, getters, rootState): DKEvent[] => {
    const locale = rootState.app.ui.locale;
    const eventFactory = new EventFactory(locale);
    const upcoming: DKEvent[] = [];

    state.events.data.upcoming.forEach((event: Event) => {
      try {
        upcoming.push(eventFactory.create(event));
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Event');
          console.warn(e);
        } else {
          throw e;
        }
      }
    });

    return upcoming;
  },
  hasMoreUpcomingEvents: (state: ContentState): boolean => {
    return state.events.data.upcoming.length < state.events.data.totalUpcoming;
  },
  totalUpcomingEvents: (state: ContentState): number => {
    return state.events.data.totalUpcoming;
  },
  pastEvents: (state: ContentState, getters, rootState): DKEvent[] => {
    const locale = rootState.app.ui.locale;
    const eventFactory = new EventFactory(locale);
    const past: DKEvent[] = [];

    state.events.data.past.forEach((event: Event) => {
      try {
        past.push(eventFactory.create(event));
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Event');
          console.warn(e);
        } else {
          throw e;
        }
      }
    });

    return past;
  },
  hasMorePastEvents: (state: ContentState): boolean => {
    return state.events.data.past.length < state.events.data.totalPast;
  },
  totalPastEvents: (state: ContentState): number => {
    return state.events.data.totalPast;
  },
  legalPage: (state: ContentState, getters, rootState): DKLegalPage | null => {
    const locale = rootState.app.ui.locale;
    let legalPage: DKLegalPage | null = null;

    if (state.legalPage.data) {
      const legalPageFactory = new LegalPageFactory(locale);
      try {
        legalPage = legalPageFactory.create(state.legalPage.data);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate LegalPage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return legalPage;
  },
  formPage: (state: ContentState, getters, rootState): DKFormPage | null => {
    const locale = rootState.app.ui.locale;
    let formPage: DKFormPage | null = null;

    if (state.formPage.data) {
      const formPageFactory = new FormPageFactory(locale);
      try {
        formPage = formPageFactory.create(state.formPage.data);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate FormPage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return formPage;
  },
  useCasePageId: (state: ContentState): string => {
    return state.useCasePageId.id;
  },
  useCasePage: (state: ContentState, getters, rootState): DKUseCase | null => {
    const locale = rootState.app.ui.locale;
    let useCasePage: DKUseCase | null = null;

    if (state.useCasePage.data) {
      const useCasePageFactory = new UseCaseFactory(locale);
      try {
        useCasePage = useCasePageFactory.create(state.useCasePage.data);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate UseCase');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return useCasePage;
  },
  useCasePageCollection: (
    state: ContentState,
    getters,
    rootState
  ): DKUseCase[] | [] => {
    const locale = rootState.app.ui.locale;
    const useCasePageCollection: DKUseCase[] = [];

    if (state.useCasePageCollection.data.items) {
      const useCasePageFactory = new UseCaseFactory(locale);
      state.useCasePageCollection.data.items.forEach((useCasePage: UseCase) => {
        try {
          useCasePageCollection.push(useCasePageFactory.create(useCasePage));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate UseCasePage');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    return useCasePageCollection;
  },
  corporateGovernancePage: (
    state: ContentState,
    getters,
    rootState
  ): DKManagement | null => {
    const locale = rootState.app.ui.locale;
    let corporateGovernancePage: DKManagement | null = null;

    if (state.corporateGovernancePage.data) {
      const corporateGovernancePageFactory = new ManagementFactory(locale);
      try {
        corporateGovernancePage = corporateGovernancePageFactory.create(
          state.corporateGovernancePage.data
        );
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate CorporateGovernancePage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return corporateGovernancePage;
  },
  careerInsightPageCollection: (
    state: ContentState,
    getters,
    rootState
  ): DKCareerInsightPage[] | [] => {
    const locale = rootState.app.ui.locale;
    const careerInsightPageCollection: DKCareerInsightPage[] = [];

    if (state.careerInsightPageCollection.data.items) {
      const careerInsightPageFactory = new CareerInsightPageFactory(locale);
      state.careerInsightPageCollection.data.items.forEach(
        (careerInsightPage: CareerInsightPage) => {
          try {
            careerInsightPageCollection.push(
              careerInsightPageFactory.create(careerInsightPage)
            );
          } catch (e) {
            if (e instanceof PropertyRequiredError) {
              console.warn('Could not generate CareerInsightPage');
              console.warn(e);
            } else {
              throw e;
            }
          }
        }
      );
    }

    return careerInsightPageCollection;
  },
  corporateGovernanceBackLink: (
    state: ContentState,
    getters,
    rootState
  ): DKLink | null => {
    const locale = rootState.app.ui.locale;
    let link: DKLink | null = null;

    if (state.corporateGovernancePage.backLink) {
      const linkFactory = new LinkFactory(locale);

      try {
        link = linkFactory.create(state.corporateGovernancePage.backLink);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return link;
  },
  generalMeetings: (
    state: ContentState,
    getters,
    rootState
  ): DKGeneralMeeting[] => {
    const locale = rootState.app.ui.locale;
    const generalMeetings: DKGeneralMeeting[] = [];

    if (state.generalMeetings.data) {
      const items: GeneralMeeting[] = state.generalMeetings.data;
      const generalMeetingFactory = new GeneralMeetingFactory(locale);

      items.forEach((generalMeeting: GeneralMeeting) => {
        try {
          generalMeetings.push(generalMeetingFactory.create(generalMeeting));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate GeneralMeeting');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    return generalMeetings;
  },
  valuePromises: (
    state: ContentState,
    getters,
    rootState
  ): DKValuePromise[] | [] => {
    const locale = rootState.app.ui.locale;
    const valuePromises: DKValuePromise[] = [];

    if (state.valuePromiseCollection.data.items) {
      const valuePromiseFactory = new ValuePromiseFactory(locale);
      state.valuePromiseCollection.data.items.forEach(
        (valuePromise: ValuePromise) => {
          try {
            valuePromises.push(valuePromiseFactory.create(valuePromise));
          } catch (e) {
            if (e instanceof PropertyRequiredError) {
              console.warn('Could not generate ValuePromises');
              console.warn(e);
            } else {
              throw e;
            }
          }
        }
      );
    }

    return valuePromises;
  },
  eqsNewsCollection: (
    state: ContentState,
    getters,
    rootState
  ): DKNewsResponse | null => {
    const locale = rootState.app.ui.locale;
    let newsResponse: DKNewsResponse | null = null;

    if (state.eqsNewsCollection.data?.items) {
      const eqsNewsResponseFactory = new EqsNewsResponseFactory(locale);

      try {
        newsResponse = eqsNewsResponseFactory.create(
          state.eqsNewsCollection.data
        );
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate EqsNewsResponse');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return newsResponse;
  },
  eqsNewsItemCategoryCollection: (
    state: ContentState
  ): EqsNewsItemCategory[] | [] => {
    return state.eqsNewsItemCategoryCollection.data || [];
  },
  eqsNewsCategoryFilter: (state: ContentState): string[] | [] => {
    return state.eqsNewsCategoryFilter || [];
  },
  hasMoreNewsItems: (state: ContentState): boolean => {
    return state.eqsNewsCollection.data
      ? state.eqsNewsCollection.data.items?.length <
          state.eqsNewsCollection.data._eqs?.total_news
      : false;
  },
  eqsNewsCollectionLoading: (state: ContentState): boolean => {
    return state.eqsNewsCollection.loading;
  },
  latestNewsLink: (state: ContentState): DKLink | null => {
    return state.eqsNewsCollection.newsLink || null;
  },
  newsPage: (state: ContentState): EqsNewsItem | null => {
    return state.newsPage.data || null;
  },
  newsPageBackLink: (
    state: ContentState,
    getters,
    rootState
  ): DKLink | null => {
    const locale = rootState.app.ui.locale;
    let link: DKLink | null = null;

    if (state.newsPage.backLink) {
      const linkFactory = new LinkFactory(locale);

      try {
        link = linkFactory.create(state.newsPage.backLink);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return link;
  },
};
