import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppHeader, { onHeaderMenuOpen: _ctx.headerMenuOpenHandler }, null, 8, ["onHeaderMenuOpen"]),
    _createElementVNode("main", {
      class: _normalizeClass(["content", {
      'content--no-pt': _ctx.isNavbarTransparent,
      'content--hidden': _ctx.isHeaderMenuOpen,
    }])
    }, [
      (_ctx.isRouterReady)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }, {
            default: _withCtx(({ Component, route }) => [
              _createVNode(_Transition, {
                name: _ctx.transitionName,
                onEnter: _ctx.enter,
                onAfterEnter: _ctx.afterEnter
              }, {
                default: _withCtx(() => [
                  (_ctx.isRouterReady)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                        class: _normalizeClass({ 'transition-no-pt': _ctx.isNavbarTransparent }),
                        key: route.path
                      }, null, 8, ["class"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["name", "onEnter", "onAfterEnter"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.isAnimating)
      ? (_openBlock(), _createBlock(_component_AppFooter, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}