import { GenericFactory } from '@/models/factories/GenericFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { EqsNewsItem, EqsNewsCollection } from '../../../types/eqs-types';
import { EqsNewsItemFactory } from '@/models/factories/EqsNewsItemFactory';
import { EqsNewsPaginationFactory } from '@/models/factories/EqsNewsPaginationFactory';
import { DKNewsResponse } from '@/models/DKNewsResponse';
import { DKNewsItem } from '@/models/DKNewsItem';
import { DKNewsPagination } from '@/models/DKNewsPagination';

export class EqsNewsResponseFactory extends GenericFactory<
  EqsNewsCollection,
  DKNewsResponse
> {
  contentType = 'EqsNewsResponse';
  requiredProperties = [];

  protected map(source: EqsNewsCollection): DKNewsResponse {
    const items: DKNewsItem[] = [];
    let pagination: DKNewsPagination | undefined;

    if (source?.items) {
      const newsItemFactory = new EqsNewsItemFactory(this.locale);
      const newsItemsList: EqsNewsItem[] = source.items;
      newsItemsList.forEach((item: EqsNewsItem) => {
        try {
          items.push(newsItemFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate NewsItems for EqsResponse');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source?._eqs) {
      const paginationFactory = new EqsNewsPaginationFactory(this.locale);
      try {
        pagination = paginationFactory.create(source._eqs);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Pagination for EqsResponse');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKNewsResponse, {
      ...source,
      items,
      pagination,
    });
  }
}
