import * as vuexStore from './store/useVuexStore';
import * as apolloStore from './store/useApolloStore';
import VueApollo from 'vue-apollo';
import createApp from './main';
import navigationGuard from '@/router/navigation-guard';
import { getStartingLocale } from '@/helpers/i18n';
import { Vue } from 'vue-facing-decorator';
import { createGtm, useGtm } from '@gtm-support/vue-gtm';
import { getGtmOptions } from '@/helpers/google';
import { createApolloClient } from '@/api/apollo-client';
import createI18n from '@/i18n';

declare global {
  interface Window {
    OneTrust: any;
    __INITIAL_STATE__: any;
    __APOLLO_STATE__: any;
    __TRANSLATIONS_STATE__: any;
  }
}

const contentfulGraphQlClient = createApolloClient();
const apolloProvider = new VueApollo({
  clients: {
    contentfulGraphQlClient,
  },
  defaultClient: contentfulGraphQlClient,
});

const _vuexStore = vuexStore._createStore();
const _apolloStore = apolloStore._createStore(apolloProvider);
const translations = window.__TRANSLATIONS_STATE__;

(async () => {
  const locale = getStartingLocale();
  const i18n = await createI18n(translations, locale);
  const { app, router } = createApp({
    vuexStore: _vuexStore,
    apolloStore: _apolloStore,
    apolloProvider,
    i18n,
  });

  app.use(router);
  Vue.prototype.$routerProvider = router;

  app.use(createGtm(getGtmOptions()));
  Vue.prototype.$gtm = useGtm();

  const initialVuexStore = window.__INITIAL_STATE__;
  if (initialVuexStore) {
    _vuexStore.replaceState(initialVuexStore);
  }

  delete window.__INITIAL_STATE__;
  delete window.__TRANSLATIONS_STATE__;

  await _vuexStore.commit('setLocale', { locale });

  router.beforeEach(
    navigationGuard.bind({ $store: _vuexStore, $router: router })
  );

  await router.isReady();

  app.mount('#app', true);
})();
