import { Asset, Quote, UseCase, Video } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKUseCase } from '@/models/DKUseCase';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { QuoteFactory } from '@/models/factories/QuoteFactory';
import { DKQuote } from '@/models/DKQuote';
import { UseCaseVideoFactory } from '@/models/factories/UseCaseVideoFactory';
import { DKUseCaseVideo } from '@/models/DKUseCaseVideo';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { DKLink } from '@/models/DKLink';
import { getLanguageFromLocale } from '@/helpers/i18n';

export class UseCaseFactory extends GenericFactory<UseCase, DKUseCase> {
  contentType = 'UseCase';
  requiredProperties = ['slug', 'title', 'headerImage', 'objectName'];

  protected map(source: UseCase): DKUseCase {
    let headerImage: DKAsset | undefined;
    let metaImage: DKAsset | undefined;
    let aboutImage: DKAsset | undefined;
    let challengesImage: DKAsset | undefined;
    let video: DKUseCaseVideo | undefined;
    let detailsLink: DKLink | undefined;
    let link: string | undefined;
    const pictureGallery: DKAsset[] = [];
    const quotes: DKQuote[] = [];

    if (source.headerImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        headerImage = assetFactory.create(source.headerImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate HeaderImage Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.metaImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.quotesCollection?.items) {
      const quoteFactory = new QuoteFactory(this.locale);
      const items: Quote[] = source.quotesCollection.items as Quote[];
      items.forEach((item: Quote) => {
        try {
          quotes.push(quoteFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Quote');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.video) {
      const useCaseVideoFactory = new UseCaseVideoFactory(this.locale);
      const item: Video = source.video as Video;

      if (item) {
        try {
          video = useCaseVideoFactory.create(item);
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Video');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      }
    }

    if (source.aboutImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        aboutImage = assetFactory.create(source.aboutImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate AboutImage Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.challengesImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        challengesImage = assetFactory.create(source.challengesImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate ChallengesImage Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.detailsLink) {
      const linkFactory = new LinkFactory(this.locale);
      try {
        detailsLink = linkFactory.create(source.detailsLink);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.pictureGalleryCollection?.items) {
      const assetFactory = new AssetFactory(this.locale);
      const items: Asset[] = source.pictureGalleryCollection.items as Asset[];
      items.forEach((item: Asset) => {
        try {
          pictureGallery.push(assetFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Picture gallery');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.isPage && source.slug) {
      link = `/${getLanguageFromLocale(this.locale)}/use-case/${source.slug}`;
    }

    return Object.assign({} as DKUseCase, {
      ...source,
      headerImage,
      metaImage,
      aboutImage,
      challengesImage,
      detailsLink,
      quotes,
      video,
      pictureGallery,
      link,
    });
  }
}
