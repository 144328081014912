import { MetaActive } from 'vue-meta';
import { notEmpty } from '@/helpers/not-empty';
import { getLanguageFromLocale, getSupportedLanguages } from '@/helpers/i18n';
import { Vue } from 'vue-facing-decorator';
import { LOCALE } from '@/store/app/models/Locale';
import { currentPath } from '@/helpers/currentPath';
import { DKMetaInfo } from '@/models/DKMetaInfo';
import { DKMetaLinkInfo } from '@/models/DKMetaLinkInfo';
import { RouteLocation } from 'vue-router';
import { getEnv } from '@/env';
import { DKNewsOverviewMetaInfo } from '@/models/DKNewsOverviewMetaInfo';
import { EqsNewsItemAttachment } from '../../types/eqs-types';
import sanitizeHtml from 'sanitize-html';
import { getExcerpt } from '@/helpers/eqs';

const baseUrl = (): string => {
  const _baseUrl = getEnv('BASE_URL');
  if (_baseUrl && _baseUrl.endsWith('/')) {
    return _baseUrl.substring(0, _baseUrl.length - 1);
  }
  return getEnv('BASE_URL') || 'www.dormakabagroup.com';
};

export const generateMetaInfo = (metaInfo: DKMetaInfo): MetaActive => {
  const metaTitle = metaInfo.page?.metaTitle || (metaInfo.page as any)?.title;
  return mapMetaInfo(
    metaTitle || Vue.prototype.$t('seo.defaultMetaTitle'),
    metaInfo.page?.metaDescription || '',
    metaInfo.page?.metaImage?.url || '',
    (metaInfo.page?.metaRobots || [])
      .filter(notEmpty)
      .map((item) => item.replace(/\s/g, '')),
    metaInfo.meta,
    metaInfo.locale,
    metaInfo.$route
  );
};

export const generateMetaInfoFromNews = (
  metaInfo: DKNewsOverviewMetaInfo
): MetaActive => {
  const headerImageUrl = metaInfo.page?.attachments?.find(
    (attachment: EqsNewsItemAttachment) =>
      attachment.mime_type.includes('image')
  )?.url;
  const description = metaInfo.page?.content_html;
  const excerpt = sanitizeHtml(getExcerpt(description, 320));

  return mapMetaInfo(
    metaInfo.page?.title || Vue.prototype.$t('seo.defaultMetaTitle'),
    excerpt || '',
    headerImageUrl || '',
    [],
    metaInfo.meta,
    metaInfo.locale,
    metaInfo.$route
  );
};

export const generateMetaLinks = (metaInfo: DKMetaLinkInfo): MetaActive => {
  const path = currentPath(metaInfo.$route);
  const meta = metaInfo.meta;
  const supportedLanguages = getSupportedLanguages();

  meta.link = [
    {
      rel: 'canonical',
      href: `${baseUrl()}${path}`,
    },
    {
      rel: 'alternate',
      href: `${baseUrl()}/en${metaInfo.links['en'] || ''}`,
      hreflang: 'x-default',
    },
  ];

  supportedLanguages.forEach((lang: string) => {
    let translatedPath = metaInfo.links[lang] || '';
    if (!translatedPath) {
      translatedPath = path;
    }
    translatedPath = translatedPath
      .replace(/(\/en|\/de)/, '')
      .split('/')
      .filter((item) => !!item)
      .join('/');
    translatedPath = translatedPath ? `/${translatedPath}` : translatedPath;

    meta.link.push({
      rel: 'alternate',
      href: `${baseUrl()}/${lang}${translatedPath}`,
      hreflang: lang,
    });
  });

  return meta;
};

const mapMetaInfo = (
  title: string,
  description: string,
  image: string,
  robots: string[],
  meta: MetaActive,
  locale: LOCALE,
  $route: RouteLocation
): MetaActive => {
  const path = currentPath($route);

  meta.htmlAttrs = {
    lang: getLanguageFromLocale(
      locale || getEnv('I18N_FALLBACK_LOCALE') || 'en-US'
    ),
    dir: 'ltr',
  };

  if (title || description || image) {
    meta.og = {
      type: 'website',
      url: `${baseUrl()}${path}`,
    };
    meta.twitter = {
      card: 'summary_large_image',
    };
  }
  if (title) {
    meta.title = title;
    meta.og.title = title;
    meta.twitter.title = title;
  }
  if (description) {
    meta.description = description;
    meta.og.description = description;
    meta.twitter.description = description;
  }
  if (image) {
    meta.og.image = image;
    meta.twitter.image = image;
  }
  if (robots.length > 0) {
    meta.robots = {
      tag: 'meta',
      name: 'robots',
      content: robots.join(',').toLowerCase(),
    };
  }
  return meta;
};
