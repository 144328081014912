import { getCookie } from '@/helpers/cookie';
import { getEnv } from '@/env';
import { LOCALE } from '@/store/app/models/Locale';

export const getStartingLocale = (): string => {
  const urlLocale = getUrlLocale();
  if (urlLocale && getSupportedLocales().includes(urlLocale)) {
    return urlLocale;
  }

  const browserLocale = getBrowserLocale();
  if (browserLocale && getSupportedLocales().includes(browserLocale)) {
    return browserLocale;
  }

  const cookieLocale = getCookie('locale');
  if (cookieLocale && getSupportedLocales().includes(cookieLocale)) {
    return cookieLocale;
  }

  return getCurrentLocale();
};

export const getCurrentLocale = (): string => {
  return getEnv('I18N_LOCALE') || getEnv('I18N_FALLBACK_LOCALE');
};

export const getFallbackLocale = (): string => {
  return getEnv('I18N_FALLBACK_LOCALE') || '';
};

export const getSupportedLocales = (): string[] => {
  if (!getEnv('I18N_SUPPORTED_LOCALES')) {
    return [];
  }
  return getEnv('I18N_SUPPORTED_LOCALES')?.split(',');
};

export const getSupportedLanguages = (): string[] => {
  const supportedLocales = getSupportedLocales();
  const languages = new Set();
  supportedLocales.forEach((locale: LOCALE) =>
    languages.add(locale.split('-')[0])
  );
  return Array.from(languages) as string[];
};

export const getLanguageFromLocale = (locale: LOCALE): string => {
  return locale.split('-')[0];
};

export const removeUrlLocale = (link: string): string => {
  const supportedLocales = getSupportedLocales();
  supportedLocales.forEach((locale: LOCALE) => {
    const language = getLanguageFromLocale(locale);
    link = link.replace(new RegExp(`/${language}(/|$)`), '/');
  });
  link = link.replace(/(^\/)|(\/$)/, '');
  return link;
};

const getUrlLocale = (): string => {
  const pathArray = window.location.pathname.trim().split('/');
  if (pathArray.length < 1) {
    return '';
  }
  // Get lang from url
  const lang = pathArray[1];
  return (
    getSupportedLocales().find((locale: string) => {
      return lang && locale.includes(lang);
    }) || ''
  );
};

function getBrowserLocale(options = {}): string {
  const defaultOptions = { countryCodeOnly: false };

  const opt = { ...defaultOptions, ...options };

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return '';
  }

  return opt.countryCodeOnly
    ? navigatorLocale.trim().split(/[-_]/)[0]
    : navigatorLocale.trim();
}
