import { Vue } from 'vue-facing-decorator';

export const getEnv = (name: string) => {
  // Cases explained:
  // If the page is served through SSR, then executed in the Browser, window.__APP_CONFIG is taken
  // If the page is served without SSR, executed in the Browser, envFallback is taken
  // If the page is executed within SSR, Vue.prototype.$env is taken

  if (typeof window !== 'undefined') {
    // @ts-ignore
    return window.__APP_CONFIG__?.[name] || envFallback[name];
  }
  // @ts-ignore
  return Vue.prototype.$env[name];
};
