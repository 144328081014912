import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarLink = _resolveComponent("NavbarLink")!
  const _component_NavbarSearch = _resolveComponent("NavbarSearch")!
  const _component_NavbarLanguageSwitcher = _resolveComponent("NavbarLanguageSwitcher")!
  const _component_NavbarLocationSwitcher = _resolveComponent("NavbarLocationSwitcher")!
  const _component_NavbarHamburger = _resolveComponent("NavbarHamburger")!
  const _component_DKBrandTag = _resolveComponent("DKBrandTag")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Megamenu = _resolveComponent("Megamenu")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass([
      'dk-header',
      {
        'dk-header--backdrop': _ctx.hasBackdrop,
        'dk-header--hidden': _ctx.isNavbarHidden,
      },
    ]),
    itemscope: "",
    itemtype: "http://schema.org/WPHeader"
  }, [
    _withDirectives(_createVNode(_component_Navbar, {
      type: _ctx.navbarType,
      isOpen: _ctx.isMenuOpen,
      homeHref: _ctx.homeHref,
      onClickHome: _ctx.onClickHome,
      onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onLeaveNavbar(_ctx.currentNavigationTreeItem)))
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_NavbarSearch, {
          isExpanded: _ctx.searchExpanded,
          onToggle: _ctx.onToggleSearch,
          onClose: _ctx.onCloseSearch
        }, null, 8, ["isExpanded", "onToggle", "onClose"]),
        _createVNode(_component_NavbarLanguageSwitcher, {
          isExpanded: _ctx.languageSwitcherExpanded,
          onToggle: _ctx.onToggleLanguageSwitcher,
          onSwitchLang: _ctx.onSwitchLang
        }, null, 8, ["isExpanded", "onToggle", "onSwitchLang"]),
        _createVNode(_component_NavbarLocationSwitcher, {
          isExpanded: _ctx.locationSwitcherExpanded,
          locationGroups: _ctx.locationGroups,
          contactNavigationTree: _ctx.contactNavigationTree,
          onClickLocationSwitcher: _ctx.onClickLocationSwitcher,
          onClickLocationLink: _ctx.onClickLocationLink
        }, null, 8, ["isExpanded", "locationGroups", "contactNavigationTree", "onClickLocationSwitcher", "onClickLocationLink"])
      ]),
      brand: _withCtx(() => [
        _createVNode(_component_DKBrandTag)
      ]),
      default: _withCtx(() => [
        (_ctx.isDesktop)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.navigationTree, (navigationTreeItem) => {
              return (_openBlock(), _createBlock(_component_NavbarLink, {
                key: navigationTreeItem.id,
                navigationTree: _ctx.navigationTree,
                navigationTreeItem: navigationTreeItem,
                onClickNavbarLink: _ctx.onClickNavbarLink,
                onClickNavbarLinkNavigate: _ctx.onClickNavbarLinkNavigate
              }, null, 8, ["navigationTree", "navigationTreeItem", "onClickNavbarLink", "onClickNavbarLinkNavigate"]))
            }), 128))
          : _createCommentVNode("", true),
        (!_ctx.isDesktop)
          ? (_openBlock(), _createBlock(_component_NavbarHamburger, {
              key: 1,
              isOpen: _ctx.isMenuOpen,
              navigationTree: _ctx.navigationTree,
              locationGroups: _ctx.mobileLocationGroups,
              contactNavigationTree: _ctx.contactNavigationTree,
              onClickHamburger: _ctx.onClickHamburger,
              onClickNavbarTab: _ctx.onClickNavbarTab,
              onClickNavbarTabToggle: _ctx.onClickNavbarTabToggle,
              onSwitchLang: _ctx.onSwitchLang,
              onClickContactLink: _ctx.onClickContactLink,
              onOnMobileSearch: _ctx.onMobileSearch
            }, null, 8, ["isOpen", "navigationTree", "locationGroups", "contactNavigationTree", "onClickHamburger", "onClickNavbarTab", "onClickNavbarTabToggle", "onSwitchLang", "onClickContactLink", "onOnMobileSearch"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["type", "isOpen", "homeHref", "onClickHome"]), [
      [_vShow, _ctx.isNavbarLoaded]
    ]),
    (_ctx.isDesktop)
      ? (_openBlock(), _createBlock(_component_Megamenu, {
          key: 0,
          type: "secondary",
          isExpanded: _ctx.isMegaMenuExpanded,
          isFull: _ctx.locationSwitcherExpanded || _ctx.isMenuOpen
        }, null, 8, ["isExpanded", "isFull"]))
      : _createCommentVNode("", true)
  ], 2))
}