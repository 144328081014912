import { RouteLocation } from 'vue-router';
import { ActionTree } from 'vuex';
import { Vue } from 'vue-facing-decorator';
import { NavigationState } from '@/store/navigation/index';
import { LOCALE } from '@/store/app/models/Locale';
import navigationCollectionQuery from '@/api/contentful-api/graphql/navigationCollection.graphql';
import navigationItemQuery from '@/api/contentful-api/graphql/navigationItem.graphql';
import linkCollectionQuery from '@/api/contentful-api/graphql/linkCollection.graphql';
import {
  LinkFilter,
  NavigationFilter,
  NavigationItemFilter,
} from '../../../types/contentful-api';
import { currentPath } from '@/helpers/currentPath';
import { MenuSelectType } from '@/store/app/models/MenuSelectType';
import { getEnv } from '@/env';

const contentfulGraphQlClient = () =>
  Vue.prototype.$apolloProvider.defaultClient;

export const actions: ActionTree<NavigationState, any> = {
  async fetchNavigationItemBySlug(
    { commit },
    payload: { path: string; locale?: LOCALE }
  ) {
    const where: NavigationItemFilter = {
      link: {
        link: payload.path,
      },
    };
    const response = await contentfulGraphQlClient().query({
      query: navigationItemQuery,
      variables: {
        locale: payload.locale || 'en-US',
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        where,
      },
    });
    commit('setNavigationItem', response.data.navigationItems.items[0]);
  },
  async fetchNavigationItems(
    { commit },
    payload: { locale?: LOCALE; $route: RouteLocation }
  ) {
    const where: NavigationFilter = {
      menuSelect_in: Object.values(MenuSelectType),
    };
    const response = await contentfulGraphQlClient().query({
      query: navigationCollectionQuery,
      variables: {
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        where,
      },
    });

    commit('setNavigations', response.data.navigations);
    commit('setNavigationItems', response.data.navigationItems);
    commit('setLinks', response.data.navigationItems);
    commit('setCurrentLinkBySlug', currentPath(payload.$route));
    commit('setReady');
  },
  async fetchLinkBySlug(
    { commit },
    payload: { path: string; locale?: LOCALE }
  ) {
    const where: LinkFilter = {
      link: `/${payload.path}`,
    };
    const response = await contentfulGraphQlClient().query({
      query: linkCollectionQuery,
      variables: {
        locale: payload.locale || 'en-US',
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        where,
      },
    });
    commit('setLink', response.data.linkCollection.items[0]);
  },
};
