import {
  createClient,
  ContentfulClientApi,
  CreateClientParams,
} from 'contentful';
import { getEnv } from '@/env';

const config: CreateClientParams = {
  host:
    getEnv('ENABLE_PREVIEW') === 'true'
      ? 'preview.contentful.com'
      : 'cdn.contentful.com',
  space: getEnv('CONTENTFUL_SPACE_ID') || '',
  accessToken: getEnv('CONTENTFUL_ACCESS_TOKEN') || '',
  environment: getEnv('CONTENTFUL_ENV') || 'master',
};

export function createContentfulClient(): ContentfulClientApi {
  return createClient(config);
}
