import { CareerInsightPage } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKCareerInsightPage } from '@/models/DKCareerInsightPage';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { getLanguageFromLocale } from '@/helpers/i18n';

export class CareerInsightPageFactory extends GenericFactory<
  CareerInsightPage,
  DKCareerInsightPage
> {
  contentType = 'CareerInsightPage';
  requiredProperties = ['slug', 'title', 'body', 'country'];

  protected map(source: CareerInsightPage): DKCareerInsightPage {
    let image: DKAsset | undefined;
    let metaImage: DKAsset | undefined;
    let link: string | undefined;
    const assetFactory = new AssetFactory(this.locale);

    if (source.image) {
      try {
        image = assetFactory.create(source.image);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Image');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.metaImage) {
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.slug) {
      link = `/${getLanguageFromLocale(this.locale)}/career/${source.slug}`;
    }

    return Object.assign({} as DKCareerInsightPage, {
      ...source,
      image,
      link,
      metaImage,
    });
  }
}
