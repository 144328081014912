import algoliasearch, { SearchIndex } from 'algoliasearch';
import { RequestOptions } from '@algolia/transporter';
import { GetObjectOptions, SearchResponse } from '@algolia/client-search';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import { getLanguageFromLocale } from '@/helpers/i18n';
import { getEnv } from '@/env';
import { LOCALE } from '@/store/app/models/Locale';
import { EqsNewsItem } from '@dormakaba/search-type-definitions/dist/eqs/item';

const algoliaEQSIndexName = (lang: string): string => {
  if (!getEnv('ALGOLIA_EQS_CONTENT_INDEX_ID')) {
    return '';
  }
  return getEnv('ALGOLIA_EQS_CONTENT_INDEX_ID')
    .replace('{language}', lang)
    .toLowerCase();
};

const CACHE_CONFIGURATION = {
  requestsCache: createInMemoryCache({ serializable: false }),
  responsesCache: createInMemoryCache(),
};

/**
 * Initialize index, allowing multiples languages
 * @param index
 */
const initIndex = (
  locale: LOCALE,
  indexNameFn: (lang: string) => string
): SearchIndex => {
  const algoliaAppId = getEnv('ALGOLIA_ID') || '';
  const algoliaApiKey = getEnv('ALGOLIA_KEY') || '';
  const lang = getLanguageFromLocale(locale);
  const client = algoliasearch(algoliaAppId, algoliaApiKey, {
    ...CACHE_CONFIGURATION,
  });
  return client.initIndex(indexNameFn(lang));
};

/**
 * Get a single news from specified index
 * @param locale
 * @param eqsNewsId
 * @param requestOptions
 */
export const getEqsNewsById = (
  locale: LOCALE,
  eqsNewsId: string,
  requestOptions?: RequestOptions & GetObjectOptions
): Readonly<Promise<EqsNewsItem>> => {
  const index = initIndex(locale, algoliaEQSIndexName);
  return index.getObject(eqsNewsId, requestOptions);
};

/**
 * Get a list of news from specified indexes list
 * @param locale
 * @param requestOptions
 */
export const getAllEqsNews = async (locale: LOCALE): Promise<EqsNewsItem[]> => {
  let hits: EqsNewsItem[] = [];
  const index = initIndex(locale ?? '', algoliaEQSIndexName);

  await index.browseObjects({
    query: '',
    batch: (batch: any) => {
      hits = hits.concat(batch);
    },
  });

  return hits;
};

/**
 * Search news by search term
 * @param locale
 * @param query
 * @param requestOptions
 */
export const searchEqsNews = (
  locale: LOCALE | undefined,
  query: string,
  requestOptions?: RequestOptions & GetObjectOptions
): Readonly<Promise<SearchResponse<EqsNewsItem>>> => {
  const index = initIndex(locale ?? '', algoliaEQSIndexName);

  return index.search(query, {
    hitsPerPage: requestOptions?.queryParameters?.hitsPerPage,
    page: requestOptions?.queryParameters?.page,
  });
};
