import {
  Asset,
  GenericPage,
  GenericPageContentFragmentsItem,
  Link,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKGenericPage } from '@/models/DKGenericPage';
import { DKAsset } from '@/models/DKAsset';
import { DKGenericPageContentFragmentsItem } from '@/models/DKGenericPageContentFragmentsItem';
import { DKLink } from '@/models/DKLink';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { GenericContentFragmentFactory } from '@/models/factories/GenericContentFragmentFactory';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { VerticalGroupFragmentFactory } from '@/models/factories/VerticalGroupFragmentFactory';
import { ContactFragmentFactory } from '@/models/factories/ContactFragmentFactory';
import { StockChartFragmentFactory } from '@/models/factories/StockChartFragmentFactory';
import { EventOverviewFragmentFactory } from '@/models/factories/EventOverviewFragmentFactory';
import { ReportFilterFragmentFactory } from '@/models/factories/ReportFilterFragmentFactory';
import { PortfolioFragmentFactory } from '@/models/factories/PortfolioFragmentFactory';
import { MediaGalleryFragmentFactory } from '@/models/factories/MediaGalleryFragmentFactory';
import { ManagementListFragmentFactory } from '@/models/factories/ManagementListFragmentFactory';
import { GeneralMeetingFragmentFactory } from '@/models/factories/GeneralMeetingFragmentFactory';
import { PageTeaserFragmentFactory } from '@/models/factories/PageTeaserFragmentFactory';
import { CtaFragmentFactory } from '@/models/factories/CtaFragmentFactory';
import { TeaserFragmentFactory } from '@/models/factories/TeaserFragmentFactory';
import { TeaserListFragmentFactory } from '@/models/factories/TeaserListFragmentFactory';
import { VideoFragmentFactory } from '@/models/factories/VideoFragmentFactory';
import { ShortiesFragmentFactory } from '@/models/factories/ShortiesFragmentFactory';
import { ContactGroupFragmentFactory } from '@/models/factories/ContactGroupFragmentFactory';
import { NewsletterSignUpFragmentFactory } from '@/models/factories/NewsletterSignUpFragmentFactory';
import { ProductDeclarationFragmentFactory } from '@/models/factories/ProductDeclarationFragmentFactory';
import { FactListFragmentFactory } from '@/models/factories/FactListFragmentFactory';
import { LatestNewsFragmentFactory } from '@/models/factories/LatestNewsFragmentFactory';
import { GenericShelfFragmentFactory } from '@/models/factories/GenericShelfFragmentFactory';
import { GenericAccordionFragmentFactory } from '@/models/factories/GenericAccordionFragmentFactory';
import { SustainabilityStrategyGraphicFragmentFactory } from '@/models/factories/SustainabilityStrategyFragmentFactory';
import { IFrameFragmentFactory } from '@/models/factories/IFrameFragmentFactory';
import { NewsOverviewFragmentFactory } from '@/models/factories/NewsOverviewFragmentFactory';
import { HtmlCodeFragmentFactory } from '@/models/factories/HtmlCodeFragmentFactory';
import { LatestBlogArticlesFragmentFactory } from '@/models/factories/LatestBlogArticlesFactory';

export class GenericPageFactory extends GenericFactory<
  GenericPage,
  DKGenericPage
> {
  contentType = 'GenericPage';
  requiredProperties = [];

  protected map(source: GenericPage): DKGenericPage {
    const linkFactory = new LinkFactory(this.locale);
    const assetFactory = new AssetFactory(this.locale);

    const headerImage = GenericPageFactory.createHeaderImage(
      source,
      assetFactory
    );
    const contentFragments = this.createContentFragments(source);
    const linkResources = this.createLinkResources(source, linkFactory);
    const assetResources = this.createAssetResources(source, assetFactory);
    const metaImage = GenericPageFactory.createMetaImage(source, assetFactory);
    const linkedFrom = this.createLinkedFrom(source, linkFactory);

    return Object.assign({} as DKGenericPage, {
      ...source,
      headerImage,
      contentFragments,
      linkResources,
      assetResources,
      metaImage,
      linkedFrom,
    });
  }

  private static createHeaderImage(
    source: GenericPage,
    assetFactory: AssetFactory
  ) {
    if (source.headerImage) {
      try {
        return assetFactory.create(source.headerImage);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate HeaderImage');
          console.warn(e);
          console.info(e.data);
        } else {
          throw e;
        }
      }
    }
  }

  private createContentFragments(source: GenericPage) {
    const contentFragments: DKGenericPageContentFragmentsItem[] = [];

    if (source.contentFragmentsCollection?.items) {
      const items: GenericPageContentFragmentsItem[] = source
        .contentFragmentsCollection.items as GenericPageContentFragmentsItem[];
      const factories: { [key: string]: GenericFactory<any, any> } = {
        ContactFragment: new ContactFragmentFactory(this.locale),
        ContactGroupFragment: new ContactGroupFragmentFactory(this.locale),
        CtaFragment: new CtaFragmentFactory(this.locale),
        EventOverviewFragment: new EventOverviewFragmentFactory(this.locale),
        GeneralMeetingFragment: new GeneralMeetingFragmentFactory(this.locale),
        GenericAccordionFragment: new GenericAccordionFragmentFactory(
          this.locale
        ),
        GenericContentFragment: new GenericContentFragmentFactory(this.locale),
        GenericShelfFragment: new GenericShelfFragmentFactory(this.locale),
        IFrameFragment: new IFrameFragmentFactory(this.locale),
        HtmlCodeFragment: new HtmlCodeFragmentFactory(this.locale),
        FactListFragment: new FactListFragmentFactory(this.locale),
        LatestBlogArticlesFragment: new LatestBlogArticlesFragmentFactory(
          this.locale
        ),
        LatestNewsFragment: new LatestNewsFragmentFactory(this.locale),
        ManagementListFragment: new ManagementListFragmentFactory(this.locale),
        MediaGalleryFragment: new MediaGalleryFragmentFactory(this.locale),
        NewsletterSignUpFragment: new NewsletterSignUpFragmentFactory(
          this.locale
        ),
        NewsOverviewFragment: new NewsOverviewFragmentFactory(this.locale),
        PageTeaserFragment: new PageTeaserFragmentFactory(this.locale),
        PortfolioFragment: new PortfolioFragmentFactory(this.locale),
        ProductDeclarationFragment: new ProductDeclarationFragmentFactory(
          this.locale
        ),
        ReportFilterFragment: new ReportFilterFragmentFactory(this.locale),
        ShortiesFragment: new ShortiesFragmentFactory(this.locale),
        StockChartFragment: new StockChartFragmentFactory(this.locale),
        SustainabilityStrategyGraphicFragment:
          new SustainabilityStrategyGraphicFragmentFactory(this.locale),
        TeaserFragment: new TeaserFragmentFactory(this.locale),
        TeaserListFragment: new TeaserListFragmentFactory(this.locale),
        VerticalGroupFragment: new VerticalGroupFragmentFactory(this.locale),
        VideoFragment: new VideoFragmentFactory(this.locale),
      };

      items.forEach((fragment: GenericPageContentFragmentsItem) => {
        const typename: string = fragment.__typename as string;
        if (Object.keys(factories).includes(typename)) {
          try {
            contentFragments.push(factories[typename].create(fragment));
          } catch (e) {
            if (e instanceof PropertyRequiredError) {
              console.warn(`Could not generate ${typename}`);
              console.warn(e);
            } else {
              throw e;
            }
          }
        } else {
          console.warn(`No mapping for ${fragment.__typename} provided!`);
        }
      });
    }

    return contentFragments;
  }

  private createLinkResources(source: GenericPage, linkFactory: LinkFactory) {
    const linkResources: DKLink[] = [];

    if (source.linkResourcesCollection?.items) {
      const items: Link[] = source.linkResourcesCollection.items as Link[];

      items.forEach((link: Link) => {
        try {
          linkResources.push(linkFactory.create(link));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate LinkResource');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    return linkResources;
  }

  private createAssetResources(
    source: GenericPage,
    assetFactory: AssetFactory
  ) {
    const assetResources: DKAsset[] = [];

    if (source.assetResourcesCollection?.items) {
      const items: Asset[] = source.assetResourcesCollection.items as Asset[];

      items.forEach((asset: Asset) => {
        try {
          assetResources.push(assetFactory.create(asset));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate AssetResource');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    return assetResources;
  }

  private static createMetaImage(
    source: GenericPage,
    assetFactory: AssetFactory
  ) {
    if (source.metaImage) {
      try {
        return assetFactory.create(source.metaImage);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }
  }

  private createLinkedFrom(source: GenericPage, linkFactory: LinkFactory) {
    const linkedFrom: DKLink[] = [];

    if (source.linkedFrom?.linkCollection?.items) {
      const items: Link[] = source.linkedFrom.linkCollection.items as Link[];

      items.forEach((link: Link) => {
        try {
          linkedFrom.push(linkFactory.create(link));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate LinkedFrom Link');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    return linkedFrom;
  }
}
