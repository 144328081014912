import { NavigationGuardNext, RouteLocation } from 'vue-router';
import {
  getLanguageFromLocale,
  getSupportedLocales,
  removeUrlLocale,
} from '@/helpers/i18n';

async function navigationGuard(
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext
) {
  // A history back must update the locale to the corresponding link otherwise a 404 page occurs
  if (
    this.$store.getters['locale'] &&
    !this.$store.getters['locale'].includes(to.params.locale)
  ) {
    const newLocale = getSupportedLocales().find((item: string) =>
      item.includes(to.params.locale as string)
    );
    if (newLocale) {
      this.$store.commit('setLocale', { locale: newLocale });
    }
  }

  const locale = this.$store.getters['locale'];
  const name = (to.name as string) || '';
  const path = removeUrlLocale(to.path);

  /*** CURRENT LINK */
  const slug = name.includes('news-detail-page') ? path : to.params.slug;
  let link = this.$store.getters['navigation/linkBySlug'](slug);

  if (!link && name !== 'home-page') {
    await this.$store.dispatch('navigation/fetchLinkBySlug', {
      path: to.params.slug,
      locale,
      $router: this.$router,
    });
    link = this.$store.getters['navigation/linkBySlug'](to.params.slug);
  }

  link && this.$store.commit('navigation/setCurrentLink', link);

  /*** INTERNATIONALIZATION */
  const currentLanguage = getLanguageFromLocale(locale);
  const languagePath = path
    ? `/${currentLanguage}/${path}`
    : `/${currentLanguage}`;

  if (to.path === '' || (to.path === '/' && !to.params.locale)) {
    return next({ path: `/${currentLanguage}`, hash: to.hash });
  }

  if (
    link &&
    to.path !== languagePath &&
    !to.path.includes(`/${currentLanguage}`)
  ) {
    return next({ path: languagePath, hash: to.hash });
  }

  next();
}

export default navigationGuard;
