import { Vertical, VerticalGroupFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKVerticalGroupFragment } from '@/models/DKVerticalGroupFragment';
import { DKVertical } from '@/models/DKVertical';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { VerticalFactory } from '@/models/factories/VerticalFactory';

export class VerticalGroupFragmentFactory extends GenericFactory<
  VerticalGroupFragment,
  DKVerticalGroupFragment
> {
  contentType = 'VerticalGroupFragment';
  requiredProperties = ['shortTitle', 'title', 'verticals'];

  protected map(source: VerticalGroupFragment): DKVerticalGroupFragment {
    const verticals: DKVertical[] = [];

    if (source.verticalsCollection?.items) {
      const verticalFactory = new VerticalFactory(this.locale);

      const items: Vertical[] = source.verticalsCollection.items as Vertical[];
      items.forEach((item: Vertical) => {
        try {
          verticals.push(verticalFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Vertical');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKVerticalGroupFragment, {
      ...source,
      verticals,
    });
  }
}
