import { ActionTree } from 'vuex';
import { AppState } from '@/store/app/index';
import { LOCALE } from '@/store/app/models/Locale';
import verticalOverviewCollectionQuery from '@/api/contentful-api/graphql/verticalOverviewCollection.graphql';
import { Vue } from 'vue-facing-decorator';
import { GenericPageFilter } from '../../../types/contentful-api';
import { getEnv } from '@/env';
import { EqsNewsLanguage } from '../../../types/eqs-types';

const contentfulGraphQlClient = () =>
  Vue.prototype.$apolloProvider.defaultClient;

export const actions: ActionTree<AppState, any> = {
  async fetchStockPrice({ commit, rootState }, payload: { locale: LOCALE }) {
    try {
      if (rootState.app.footer.stockPrice) {
        return;
      }

      const language: EqsNewsLanguage =
        payload.locale === 'de-DE'
          ? EqsNewsLanguage.GERMAN
          : EqsNewsLanguage.ENGLISH;

      const stockPriceUrl = getEnv('EQS_STOCK_PRICE_URL');
      const baseStockPriceUrl = stockPriceUrl.replace('{language}', language);
      const response = await fetch(baseStockPriceUrl);

      const stockPrice = await response.json();

      commit('setStockPrice', stockPrice);
    } catch (e) {
      console.error('Could not find stock price!');
      console.error(e);
    }
  },
  async fetchVerticalOverviews({ commit }, payload: { locale?: LOCALE }) {
    const where: GenericPageFilter = { mainPageOf: 'Vertical Overview' };
    const response = await contentfulGraphQlClient().query({
      query: verticalOverviewCollectionQuery,
      variables: {
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        where,
      },
    });
    commit('setVerticalOverview', response.data.genericPageCollection);
  },
};
