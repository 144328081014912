import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKVerticalOverview } from '@/models/DKVerticalOverview';
import { DKLink } from '@/models/DKLink';
import { DKVerticalGroupFragment } from '@/models/DKVerticalGroupFragment';
import {
  GenericPage,
  GenericPageContentFragmentsItem,
  Link,
} from '../../../types/contentful-api';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { VerticalGroupFragmentFactory } from '@/models/factories/VerticalGroupFragmentFactory';
import { DKGenericPage } from '@/models/DKGenericPage';
import { notEmpty } from '@/helpers/not-empty';

export class VerticalOverviewFactory extends GenericFactory<
  GenericPage,
  DKVerticalOverview
> {
  contentType = 'VerticalOverview';
  requiredProperties = ['linkedFrom', 'title', 'contentFragments'];

  protected map(source: GenericPage): DKVerticalOverview {
    const linkedFrom: DKLink[] = [];
    const contentFragments: DKVerticalGroupFragment[] = [];

    if (source.linkedFrom?.linkCollection?.items) {
      const items: Link[] = source.linkedFrom.linkCollection.items as Link[];
      const linkFactory = new LinkFactory(this.locale);

      items.forEach((link: Link) => {
        try {
          linkedFrom.push(linkFactory.create(link));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate LinkedFrom Link');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.contentFragmentsCollection?.items) {
      const items: GenericPageContentFragmentsItem[] = source
        .contentFragmentsCollection.items as GenericPageContentFragmentsItem[];
      const verticalGroupFragmentFactory = new VerticalGroupFragmentFactory(
        this.locale
      );

      items
        .filter(notEmpty)
        .forEach((fragment: GenericPageContentFragmentsItem) => {
          if (fragment.__typename === 'VerticalGroupFragment') {
            try {
              contentFragments.push(
                verticalGroupFragmentFactory.create(fragment)
              );
            } catch (e) {
              if (e instanceof PropertyRequiredError) {
                console.warn('Could not generate VerticalGroupFragment');
                console.warn(e);
              } else {
                throw e;
              }
            }
          }
        });
    }

    return Object.assign({} as DKGenericPage, {
      ...source,
      linkedFrom,
      contentFragments,
    });
  }
}
