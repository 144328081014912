import { GenericFactory } from '@/models/factories/GenericFactory';
import { EqsNewsPagination } from '../../../types/eqs-types';
import { DKNewsPagination } from '@/models/DKNewsPagination';

export class EqsNewsPaginationFactory extends GenericFactory<
  EqsNewsPagination,
  DKNewsPagination
> {
  contentType = 'EqsNewsPagination';
  requiredProperties = [];

  protected map(source: EqsNewsPagination): DKNewsPagination {
    return Object.assign({} as DKNewsPagination, {
      ...source,
    });
  }
}
