import { GenericFactory } from '@/models/factories/GenericFactory';
import { EqsNewsItemCategory } from '../../../types/eqs-types';
import { DKNewsItemCategory } from '@/models/DKNewsItemCategory';

export class EqsNewsCategoryFactory extends GenericFactory<
  EqsNewsItemCategory,
  DKNewsItemCategory
> {
  contentType = 'EqsNewsCategory';
  requiredProperties = ['name', 'slug'];

  protected map(source: EqsNewsItemCategory): DKNewsItemCategory {
    return Object.assign({} as DKNewsItemCategory, source);
  }
}
