export interface EqsNewsCollection {
  items: EqsNewsItem[];
  _eqs: EqsNewsPagination;
}

export interface EqsNewsOptions {
  language: EqsNewsLanguageType;
  type?: EqsNewsItemType;
  category?: EqsNewsCategoryType[];
  start?: number;
  limit?: number;
}

export interface EqsNewsItem {
  id: string;
  title: string;
  summary?: string;
  content_html: string;
  date_published: string;
  _eqs: {
    categories: EqsNewsItemCategory[];
    news_type: EqsNewsItemType;
  };
  attachments?: EqsNewsItemAttachment[];
}

export interface EqsNewsPagination {
  total_news: number;
  total_pages: number;
}

export interface EqsNewsItemAttachment {
  title: string;
  mime_type: string;
  url: string;
}

export interface EqsNewsItemCategory {
  name: string;
  slug: EqsNewsCategoryType;
}

export enum EqsNewsLanguage {
  ENGLISH = 'English',
  GERMAN = 'German',
}
export enum EqsCategorySlug {
  ADHOC = 'ad-hoc',
  INNOVATION = 'innovation-and-digitization',
  PRODUCTS = 'products',
  SUSTAINABILITY = 'sustainability',
  TRADE_FAIRS = 'trade-fairs',
  DORMAKABA_NEWS = 'dormakaba-news',
}

export enum EqsNewsItemTypeEnum {
  ADHOC = 'adhoc',
  CORPORATE = 'corporate',
  ALL = 'all',
}
export type EqsNewsLanguageType = 'English' | 'German';
export type EqsNewsItemType = 'adhoc' | 'corporate' | 'all';
export type EqsNewsCategoryType =
  | 'ad-hoc'
  | 'innovation-and-digitization'
  | 'products'
  | 'sustainability'
  | 'trade-fairs'
  | 'dormakaba-news';
