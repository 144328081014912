import {
  HomePage,
  HomePageContentFragmentsItem,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKHomePage } from '@/models/DKHomePage';
import { DKHomePageContentFragmentsItem } from '@/models/DKHomePageContentFragmentsItem';
import { DKAsset } from '@/models/DKAsset';
import { PageTeaserFragmentFactory } from '@/models/factories/PageTeaserFragmentFactory';
import { TeaserFragmentFactory } from '@/models/factories/TeaserFragmentFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { LatestNewsFragmentFactory } from '@/models/factories/LatestNewsFragmentFactory';
import { ContactFragmentFactory } from '@/models/factories/ContactFragmentFactory';
import { ContactGroupFragmentFactory } from '@/models/factories/ContactGroupFragmentFactory';
import { CtaFragmentFactory } from '@/models/factories/CtaFragmentFactory';
import { EventOverviewFragmentFactory } from '@/models/factories/EventOverviewFragmentFactory';
import { FactListFragmentFactory } from '@/models/factories/FactListFragmentFactory';
import { GeneralMeetingFragmentFactory } from '@/models/factories/GeneralMeetingFragmentFactory';
import { GenericAccordionFragmentFactory } from '@/models/factories/GenericAccordionFragmentFactory';
import { GenericContentFragmentFactory } from '@/models/factories/GenericContentFragmentFactory';
import { GenericShelfFragmentFactory } from '@/models/factories/GenericShelfFragmentFactory';
import { IFrameFragmentFactory } from '@/models/factories/IFrameFragmentFactory';
import { ManagementListFragmentFactory } from '@/models/factories/ManagementListFragmentFactory';
import { MediaGalleryFragmentFactory } from '@/models/factories/MediaGalleryFragmentFactory';
import { NewsletterSignUpFragmentFactory } from '@/models/factories/NewsletterSignUpFragmentFactory';
import { PortfolioFragmentFactory } from '@/models/factories/PortfolioFragmentFactory';
import { ProductDeclarationFragmentFactory } from '@/models/factories/ProductDeclarationFragmentFactory';
import { ReportFilterFragmentFactory } from '@/models/factories/ReportFilterFragmentFactory';
import { ShortiesFragmentFactory } from '@/models/factories/ShortiesFragmentFactory';
import { StockChartFragmentFactory } from '@/models/factories/StockChartFragmentFactory';
import { SustainabilityStrategyGraphicFragmentFactory } from '@/models/factories/SustainabilityStrategyFragmentFactory';
import { TeaserListFragmentFactory } from '@/models/factories/TeaserListFragmentFactory';
import { VerticalGroupFragmentFactory } from '@/models/factories/VerticalGroupFragmentFactory';
import { VideoFragmentFactory } from '@/models/factories/VideoFragmentFactory';
import { HtmlCodeFragmentFactory } from '@/models/factories/HtmlCodeFragmentFactory';
import { LatestBlogArticlesFragmentFactory } from './LatestBlogArticlesFactory';

export class HomePageFactory extends GenericFactory<HomePage, DKHomePage> {
  contentType = 'HomePage';
  requiredProperties = ['identifier'];

  protected map(source: HomePage): DKHomePage {
    let metaImage: DKAsset | undefined;

    const contentFragments = this.createContentFragments(source);

    if (source.metaImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKHomePage, {
      ...source,
      contentFragments,
      metaImage,
    });
  }

  private createContentFragments(source: HomePage) {
    const contentFragments: DKHomePageContentFragmentsItem[] = [];

    if (source.contentFragmentsCollection?.items) {
      const items: HomePageContentFragmentsItem[] = source
        .contentFragmentsCollection.items as HomePageContentFragmentsItem[];
      const factories: { [key: string]: GenericFactory<any, any> } = {
        ContactFragment: new ContactFragmentFactory(this.locale),
        ContactGroupFragment: new ContactGroupFragmentFactory(this.locale),
        CtaFragment: new CtaFragmentFactory(this.locale),
        EventOverviewFragment: new EventOverviewFragmentFactory(this.locale),
        FactListFragment: new FactListFragmentFactory(this.locale),
        GeneralMeetingFragment: new GeneralMeetingFragmentFactory(this.locale),
        GenericAccordionFragment: new GenericAccordionFragmentFactory(
          this.locale
        ),
        GenericContentFragment: new GenericContentFragmentFactory(this.locale),
        GenericShelfFragment: new GenericShelfFragmentFactory(this.locale),
        HtmlCodeFragment: new HtmlCodeFragmentFactory(this.locale),
        IFrameFragment: new IFrameFragmentFactory(this.locale),
        LatestBlogArticlesFragment: new LatestBlogArticlesFragmentFactory(
          this.locale
        ),
        LatestNewsFragment: new LatestNewsFragmentFactory(this.locale),
        ManagementListFragment: new ManagementListFragmentFactory(this.locale),
        MediaGalleryFragment: new MediaGalleryFragmentFactory(this.locale),
        NewsletterSignUpFragment: new NewsletterSignUpFragmentFactory(
          this.locale
        ),
        PageTeaserFragment: new PageTeaserFragmentFactory(this.locale),
        PortfolioFragment: new PortfolioFragmentFactory(this.locale),
        ProductDeclarationFragment: new ProductDeclarationFragmentFactory(
          this.locale
        ),
        ReportFilterFragment: new ReportFilterFragmentFactory(this.locale),
        ShortiesFragment: new ShortiesFragmentFactory(this.locale),
        StockChartFragment: new StockChartFragmentFactory(this.locale),
        SustainabilityStrategyGraphicFragment:
          new SustainabilityStrategyGraphicFragmentFactory(this.locale),
        TeaserFragment: new TeaserFragmentFactory(this.locale),
        TeaserListFragment: new TeaserListFragmentFactory(this.locale),
        VerticalGroupFragment: new VerticalGroupFragmentFactory(this.locale),
        VideoFragment: new VideoFragmentFactory(this.locale),
      };

      items.forEach((fragment: HomePageContentFragmentsItem) => {
        const typename: string = fragment.__typename as string;
        if (Object.keys(factories).includes(typename)) {
          try {
            contentFragments.push(factories[typename].create(fragment));
          } catch (e) {
            if (e instanceof PropertyRequiredError) {
              console.warn(`Could not generate ${typename}`);
              console.warn(e);
            } else {
              throw e;
            }
          }
        } else {
          console.warn(`No mapping for ${fragment.__typename} provided!`);
        }
      });
    }

    return contentFragments;
  }
}
