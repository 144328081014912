import { ActionTree } from 'vuex';
import { Vue } from 'vue-facing-decorator';
import { ContentState } from '@/store/content/index';
import { LOCALE } from '@/store/app/models/Locale';
import { EventCategory } from '@/models/EventCategory';
import homePageQuery from '@/api/contentful-api/graphql/homePage.graphql';
import genericPageQuery from '@/api/contentful-api/graphql/genericPage.graphql';
import reusablePageQuery from '@/api/contentful-api/graphql/reusablePage.graphql';
import newsDetailPageQuery from '@/api/contentful-api/graphql/newsDetailPage.graphql';
import careerInsightPageIdQuery from '@/api/contentful-api/graphql/careerInsightPageId.graphql';
import careerInsightPageQuery from '@/api/contentful-api/graphql/careerInsightPage.graphql';
import careerInsightPageCollectionQuery from '@/api/contentful-api/graphql/careerInsightPageCollection.graphql';
import legalPageQuery from '@/api/contentful-api/graphql/legalPage.graphql';
import formPageQuery from '@/api/contentful-api/graphql/formPage.graphql';
import useCasePageIdQuery from '@/api/contentful-api/graphql/useCasePageId.graphql';
import useCasePageQuery from '@/api/contentful-api/graphql/useCasePage.graphql';
import useCasePageCollectionQuery from '@/api/contentful-api/graphql/useCasePageCollection.graphql';
import reportCollectionQuery from '@/api/contentful-api/graphql/reportCollection.graphql';
import reportTopicCollectionQuery from '@/api/contentful-api/graphql/reportTopicCollection.graphql';
import eventCollectionQuery from '@/api/contentful-api/graphql/eventCollection.graphql';
import portfolioFragmentQuery from '@/api/contentful-api/graphql/portfolioFragment.graphql';
import corporateGovernancePageQuery from '@/api/contentful-api/graphql/corporateGovernancePage.graphql';
import generalMeetingCollectionQuery from '@/api/contentful-api/graphql/generalMeetingCollection.graphql';
import verticalGroupFragmentsQuery from '@/api/contentful-api/graphql/verticalGroupFragment.graphql';
import shortiesFragmentsQuery from '@/api/contentful-api/graphql/shortiesFragment.graphql';
import videoFragmentQuery from '@/api/contentful-api/graphql/videoFragment.graphql';
import contactGroupFragmentQuery from '@/api/contentful-api/graphql/contactGroupFragment.graphql';
import productDeclarationFragmentQuery from '@/api/contentful-api/graphql/productDeclarationFragment.graphql';
import pageTeaserFragmentQuery from '@/api/contentful-api/graphql/pageTeaserFragment.graphql';
import genericShelfFragmentQuery from '@/api/contentful-api/graphql/genericShelfFragment.graphql';
import reportFilterFragmentQuery from '@/api/contentful-api/graphql/reportFilterFragment.graphql';
import contactFragmentQuery from '@/api/contentful-api/graphql/contactFragment.graphql';
import ctaFragmentQuery from '@/api/contentful-api/graphql/ctaFragment.graphql';
import eventOverviewFragmentQuery from '@/api/contentful-api/graphql/eventOverviewFragment.graphql';
import generalMeetingFragmentQuery from '@/api/contentful-api/graphql/generalMeetingFragment.graphql';
import genericContentFragmentQuery from '@/api/contentful-api/graphql/genericContentFragment.graphql';
import managementListFragmentQuery from '@/api/contentful-api/graphql/managementListFragment.graphql';
import mediaGalleryFragmentQuery from '@/api/contentful-api/graphql/mediaGalleryFragment.graphql';
import newsletterSignUpFragmentQuery from '@/api/contentful-api/graphql/newsletterSignUpFragment.graphql';
import teaserFragmentQuery from '@/api/contentful-api/graphql/teaserFragment.graphql';
import teaserListFragmentQuery from '@/api/contentful-api/graphql/teaserListFragment.graphql';
import stockChartFragmentQuery from '@/api/contentful-api/graphql/stockChartFragment.graphql';
import newsOverviewFragmentQuery from '@/api/contentful-api/graphql/newsOverviewFragment.graphql';
import quoteQuery from '@/api/contentful-api/graphql/quote.graphql';
import factListFragmentQuery from '@/api/contentful-api/graphql/factListFragment.graphql';
import latestNewsFragmentQuery from '@/api/contentful-api/graphql/latestNewsFragment.graphql';
import latestBlogArticlesFragmentQuery from '@/api/contentful-api/graphql/latestBlogArticlesFragment.graphql';
import genericAccordionFragmentQuery from '@/api/contentful-api/graphql/genericAccordionFragment.graphql';
import sustainabilityStrategyGraphicFragmentQuery from '@/api/contentful-api/graphql/sustainabilityStrategyGraphicFragment.graphql';
import iframeFragmentQuery from '@/api/contentful-api/graphql/iframeFragment.graphql';
import htmlCodeFragmentQuery from '@/api/contentful-api/graphql/htmlCodeFragment.graphql';
import { FooterStatus } from '@/store/app/models/FooterStatus';
import { today } from '@/helpers/today';
import { createContentfulClient } from '@/plugins/contentful-client';
import {
  CareerInsightPageFilter,
  GenericPageContentFragmentsItem,
  NewsDetailPageContentFragmentsItem,
  HomePageContentFragmentsItem,
  ManagementFilter,
  ReportFilter,
  UseCaseFilter,
  NewsDetailPageBody,
} from '../../../types/contentful-api';
import { notEmpty } from '@/helpers/not-empty';
import { hasId } from '@/helpers/has-id';
import { redirectTo404 } from '@/helpers/redirect-to-404';
import { Router, RouteLocation } from 'vue-router';
import { getEnv } from '@/env';
import {
  EqsNewsLanguageType,
  EqsNewsCollection,
} from '../../../types/eqs-types';
import { EqsApi } from '@/api/eqs-api/eqs-api';
import { cloneDeep } from 'lodash';
import {
  videoConverter,
  externalVideoConverter,
} from '@/helpers/richtext-video-converter';

const contentfulGraphQlClient = () =>
  Vue.prototype.$apolloProvider.defaultClient;

const eqsApi = new EqsApi(getEnv('EQS_COMPANY_TOKEN'));

export const actions: ActionTree<ContentState, any> = {
  async fetchHomePage(
    { commit, dispatch },
    payload: {
      id: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      if (!payload.id) {
        throw new Error('No home-page id provided!');
      }
      const response = await contentfulGraphQlClient().query({
        query: homePageQuery,
        variables: {
          id: payload.id,
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
        },
      });
      if (!response?.data?.homePage) {
        throw new Error('No home-page found!');
      }

      const contentFragments: HomePageContentFragmentsItem[] =
        response.data.homePage.contentFragmentsCollection?.items
          ?.filter(notEmpty)
          .filter(hasId) || [];

      const fragmentPromises: Promise<any>[] = [];
      for (const fragment of contentFragments) {
        let fetchFragmentAction;
        switch (fragment.__typename) {
          case 'ContactFragment':
          case 'ContactGroupFragment':
          case 'CtaFragment':
          case 'EventOverviewFragment':
          case 'FactListFragment':
          case 'GenericAccordionFragment':
          case 'GeneralMeetingFragment':
          case 'GenericContentFragment':
          case 'GenericShelfFragment':
          case 'HtmlCodeFragment':
          case 'IFrameFragment':
          case 'LatestBlogArticlesFragment':
          case 'LatestNewsFragment':
          case 'ManagementListFragment':
          case 'MediaGalleryFragment':
          case 'NewsletterSignUpFragment':
          case 'PageTeaserFragment':
          case 'ReportFilterFragment':
          case 'PortfolioFragment':
          case 'ProductDeclarationFragment':
          case 'ShortiesFragment':
          case 'StockChartFragment':
          case 'SustainabilityStrategyGraphicFragment':
          case 'TeaserFragment':
          case 'TeaserListFragment':
          case 'VerticalGroupFragment':
          case 'VideoFragment':
            fetchFragmentAction = `fetch${fragment.__typename}`;
            break;
          default:
            console.warn(`${fragment.__typename} is not fetched yet!`);
        }

        if (fetchFragmentAction) {
          fragmentPromises.push(
            dispatch(fetchFragmentAction, { ...payload, id: fragment.sys.id })
          );
        }
      }

      await Promise.allSettled(fragmentPromises);

      commit('setHomePage', response.data);
      commit('setHomeSearchTerms', response.data);
      commit(
        'setValuePromiseCollection',
        response.data.homePage.valuePromisesCollection?.items
      );
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchGenericPage(
    { commit, dispatch },
    payload: {
      id: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      commit('clearGenericPage');
      if (!payload.id) {
        throw new Error('No generic-page id provided!');
      }
      const response = await contentfulGraphQlClient().query({
        query: genericPageQuery,
        variables: {
          id: payload.id,
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
        },
      });
      if (!response?.data?.genericPage) {
        throw new Error('No generic-page found!');
      }

      const contentFragments: GenericPageContentFragmentsItem[] =
        response.data.genericPage.contentFragmentsCollection?.items
          ?.filter(notEmpty)
          .filter(hasId) || [];

      const fragmentPromises: Promise<any>[] = [];
      for (const fragment of contentFragments) {
        let fetchFragmentAction;
        switch (fragment.__typename) {
          case 'ContactFragment':
          case 'ContactGroupFragment':
          case 'CtaFragment':
          case 'EventOverviewFragment':
          case 'FactListFragment':
          case 'GenericAccordionFragment':
          case 'GeneralMeetingFragment':
          case 'GenericContentFragment':
          case 'GenericShelfFragment':
          case 'HtmlCodeFragment':
          case 'IFrameFragment':
          case 'LatestBlogArticlesFragment':
          case 'LatestNewsFragment':
          case 'ManagementListFragment':
          case 'MediaGalleryFragment':
          case 'NewsletterSignUpFragment':
          case 'NewsOverviewFragment':
          case 'PageTeaserFragment':
          case 'ReportFilterFragment':
          case 'PortfolioFragment':
          case 'ProductDeclarationFragment':
          case 'ShortiesFragment':
          case 'StockChartFragment':
          case 'SustainabilityStrategyGraphicFragment':
          case 'TeaserFragment':
          case 'TeaserListFragment':
          case 'VerticalGroupFragment':
          case 'VideoFragment':
            fetchFragmentAction = `fetch${fragment.__typename}`;
            break;
          default:
            console.warn(`${fragment.__typename} is not fetched yet!`);
        }

        if (fetchFragmentAction) {
          fragmentPromises.push(
            dispatch(fetchFragmentAction, { ...payload, id: fragment.sys.id })
          );
        }
      }

      await Promise.allSettled(fragmentPromises);

      commit('setGenericPage', response.data);
      commit('setHeaderType', response.data.genericPage.header, { root: true });
      commit(
        'setFooterGroup',
        response.data.genericPage.footer === FooterStatus.GROUP_FOOTER,
        {
          root: true,
        }
      );
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchReusablePage(
    { commit },
    payload: {
      id: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      commit('clearReusablePage');

      if (!payload.id) {
        throw new Error(`No reusable-page id provided!`);
      }

      const response = await contentfulGraphQlClient().query({
        query: reusablePageQuery,
        variables: {
          id: payload.id,
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
        },
      });

      if (!response?.data?.reusablePage) {
        throw new Error('No reusable-page found!');
      }

      commit('setReusablePage', response.data);
      commit(
        'setFooterGroup',
        response.data.reusablePage.footer === FooterStatus.GROUP_FOOTER,
        {
          root: true,
        }
      );
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchNewsDetailPage(
    { commit, dispatch },
    payload: {
      id: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      commit('clearNewsDetailPage');
      if (!payload.id) {
        throw new Error(`No news-detail-page id provided!`);
      }

      const response = await contentfulGraphQlClient().query({
        query: newsDetailPageQuery,
        variables: {
          id: payload.id,
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
        },
      });
      if (!response?.data?.newsDetailPage) {
        throw new Error(`No news-detail-page found!`);
      }

      const contentFragments: NewsDetailPageContentFragmentsItem[] =
        response.data.newsDetailPage.contentFragmentsCollection?.items
          ?.filter(notEmpty)
          .filter(hasId) || [];

      const fragmentPromises: Promise<any>[] = [];
      for (const fragment of contentFragments) {
        let fetchFragmentAction;
        switch (fragment?.__typename) {
          case 'ContactFragment':
          case 'Quote':
            fetchFragmentAction = `fetch${fragment?.__typename}`;
            break;
          default:
            console.warn(`${fragment?.__typename} is not fetched  yet!`);
        }
        if (fetchFragmentAction) {
          fragmentPromises.push(
            dispatch(fetchFragmentAction, { ...payload, id: fragment.sys.id })
          );
        }
      }

      await Promise.allSettled(fragmentPromises);

      const richTextBody: NewsDetailPageBody = cloneDeep(
        response.data.newsDetailPage.body
      );
      const { entries } = richTextBody.links;

      if (entries) {
        // In this specific case, it was necessary to retrieve the data from contentful,
        // via the ‘getEntries’ method, because when adding entries to the richtext,
        // contentful does not allow it to be fully populated

        const contentfulClient = createContentfulClient();
        const entriesBlock = entries.block;
        const types = {
          Video: videoConverter,
          ExternalVideo: externalVideoConverter,
        };

        for (const [index, entry] of (entriesBlock as any[]).entries()) {
          if (entry) {
            try {
              const assetEntry: any = await contentfulClient.getEntry(
                entry.sys.id
              );
              const videoType = types[entry.__typename];

              if (videoType) {
                entriesBlock[index] = videoType(assetEntry);
              }
            } catch (e: unknown) {
              throw new Error(`${e} - No news-detail-page asset-entry found!`);
            }
          }
        }
      }

      response.data.newsDetailPage = {
        ...response.data.newsDetailPage,
        body: richTextBody,
      };

      commit('setNewsDetailPage', response.data);
      commit('setHeaderType', response.data.newsDetailPage.header, {
        root: true,
      });
      commit(
        'setFooterGroup',
        response.data.newsDetailPage.footer === FooterStatus.GROUP_FOOTER,
        {
          root: true,
        }
      );
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchCareerInsightPageId(
    { commit },
    payload: {
      slug: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      commit('clearCareerInsightPageId');

      if (!payload.slug) {
        throw new Error('No career-insight-page slug provided!');
      }

      const where: CareerInsightPageFilter = {
        slug_in: [payload.slug],
      };

      const response = await contentfulGraphQlClient().query({
        query: careerInsightPageIdQuery,
        variables: {
          locale: payload.locale,
          fallbackLocale: getEnv('I18N_FALLBACK_LOCALE'),
          preview: getEnv('ENABLE_PREVIEW') === 'true',
          where,
        },
      });

      const pageId = response.data.requestedLocaleCareerInsightPageId.items
        .length
        ? response?.data?.requestedLocaleCareerInsightPageId?.items[0].sys.id
        : response?.data?.fallbackLocaleCareerInsightPageId?.items[0].sys.id;

      commit('setCareerInsightPageId', pageId);
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchCareerInsightPage(
    { commit },
    payload: {
      id: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      commit('clearCareerInsightPage');

      if (!payload.id) {
        throw new Error('No career-insight-page slug provided!');
      }

      const where: CareerInsightPageFilter = {
        sys: {
          id: payload.id,
        },
      };

      const response = await contentfulGraphQlClient().query({
        query: careerInsightPageQuery,
        variables: {
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
          where,
        },
      });

      if (!response?.data?.careerInsightPageCollection?.items.length) {
        throw new Error('No career-insight-page found!');
      }

      commit('setCareerInsightPage', response.data);
      commit('setFooterGroup', false, { root: true });
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchCareerInsightPageCollection(
    { commit },
    payload: {
      slug: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      const where: CareerInsightPageFilter = {
        slug_not: payload.slug,
      };

      const response = await contentfulGraphQlClient().query({
        query: careerInsightPageCollectionQuery,
        variables: {
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
          where,
        },
      });

      commit('setCareerInsightPageCollection', response.data);
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchLegalPage(
    { commit },
    payload: {
      id: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      commit('clearLegalPage');
      if (!payload.id) {
        throw new Error('No legal-page id provided!');
      }
      const response = await contentfulGraphQlClient().query({
        query: legalPageQuery,
        variables: {
          id: payload.id,
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
        },
      });
      if (!response?.data?.legalPage) {
        throw new Error('No legal-page found!');
      }
      commit('setLegalPage', response.data);
      commit('setFooterGroup', false, { root: true });
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchFormPage(
    { commit },
    payload: {
      id: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      if (!payload.id) {
        throw new Error('No form-page id provided!');
      }
      const response = await contentfulGraphQlClient().query({
        query: formPageQuery,
        variables: {
          id: payload.id,
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
        },
      });
      if (!response?.data?.formPage) {
        throw new Error('No form-page found!');
      }
      commit('setFormPage', response.data);
      commit('setFooterGroup', false, { root: true });
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchUseCasePageId(
    { commit },
    payload: {
      slug: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      commit('clearUseCasePageId');

      if (!payload.slug) {
        throw new Error('No use-case-page slug provided!');
      }

      const where: UseCaseFilter = {
        slug_in: [payload.slug],
      };

      const response = await contentfulGraphQlClient().query({
        query: useCasePageIdQuery,
        variables: {
          locale: payload.locale,
          fallbackLocale: getEnv('I18N_FALLBACK_LOCALE'),
          preview: getEnv('ENABLE_PREVIEW') === 'true',
          where,
        },
      });

      const pageId = response.data.requestedLocaleUseCasePageId.items.length
        ? response?.data?.requestedLocaleUseCasePageId?.items[0].sys.id
        : response?.data?.fallbackLocaleUseCasePageId?.items[0].sys.id;

      commit('setUseCasePageId', pageId);
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchUseCasePage(
    { commit },
    payload: {
      id: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      commit('clearUseCasePage');

      if (!payload.id) {
        throw new Error('No use-case-page id provided!');
      }

      const where: UseCaseFilter = {
        sys: {
          id: payload.id,
        },
      };

      const response = await contentfulGraphQlClient().query({
        query: useCasePageQuery,
        variables: {
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
          where,
        },
      });

      if (!response?.data?.useCaseCollection?.items.length) {
        throw new Error('No use-case-page found!');
      }

      if (!response.data?.useCaseCollection?.items[0].isPage) {
        return redirectTo404(payload.locale, payload.$route, payload.$router);
      }

      commit('setUseCasePage', response.data);
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchUseCasePageCollection(
    { commit },
    payload: {
      slug: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      const where: UseCaseFilter = {
        slug_not: payload.slug,
        isPage: true,
      };

      const response = await contentfulGraphQlClient().query({
        query: useCasePageCollectionQuery,
        variables: {
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
          where,
        },
      });

      commit('setUseCasePageCollection', response.data);
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchReportCollection(
    { commit },
    payload: {
      locale?: LOCALE;
      limit: number;
      skip: number;
      topics: string[];
      yearBeginning: string;
      yearEnd: string;
      clear?: boolean;
    }
  ) {
    const conditionsYear: ReportFilter[] = [];
    const conditionsTopics: ReportFilter[] = [];

    if (payload.topics.length > 1) {
      for (const topic of payload.topics) {
        conditionsTopics.push({ reportTopic: { slug: topic } });
      }
    } else {
      conditionsTopics.push({ reportTopic: { slug: payload.topics[0] } });
    }
    if (payload.yearBeginning) {
      conditionsYear.push({ publicationDate_gte: payload.yearBeginning });
    }
    if (payload.yearEnd) {
      conditionsYear.push({ publicationDate_lte: payload.yearEnd });
    }

    const where: any = {};
    if (conditionsTopics.length) {
      where['OR'] = conditionsTopics;
    }
    if (conditionsYear.length) {
      where['AND'] = conditionsYear;
    }

    const response = await contentfulGraphQlClient().query({
      query: reportCollectionQuery,
      variables: {
        locale: payload.locale,
        limit: payload.limit,
        skip: payload.skip,
        where,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    if (payload.clear) {
      commit('clearReportCollection');
    }
    commit('setReportCollection', response.data);
  },
  async fetchReportTopicCollection({ commit }, payload: { locale?: LOCALE }) {
    const response = await contentfulGraphQlClient().query({
      query: reportTopicCollectionQuery,
      variables: {
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setReportTopicCollection', response.data);
  },
  async fetchUpcomingEvents(
    { commit },
    payload: {
      locale?: LOCALE;
      limit?: number;
      skip?: number;
      category?: EventCategory;
    }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: eventCollectionQuery,
      variables: {
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        where: {
          date_gte: today().toString(),
          category: payload.category,
        },
        order: ['date_ASC'],
        limit: payload.limit,
        skip: payload.skip,
      },
    });

    commit('setUpcomingEvents', response.data);
  },
  async fetchPastEvents(
    { commit },
    payload: {
      locale?: LOCALE;
      limit?: number;
      skip?: number;
      category?: EventCategory;
    }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: eventCollectionQuery,
      variables: {
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        where: {
          date_lt: today().toString(),
          category: payload.category,
        },
        order: ['date_DESC'],
        limit: payload.limit,
        skip: payload.skip,
      },
    });

    commit('setPastEvents', response.data);
  },
  async fetchPortfolioFragment(
    { commit },
    payload: { id: string; locale?: LOCALE; limit?: number; skip?: number }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: portfolioFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        limit: payload.limit,
        skip: payload.skip,
      },
    });

    commit('setFragment', {
      id: payload.id,
      data: response.data.portfolioFragment,
    });
  },
  async fetchCorporateGovernancePage(
    { commit },
    payload: {
      slug: string;
      locale: LOCALE;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      if (!payload.slug) {
        throw new Error('No corporate-governance-page slug provided!');
      }

      commit('clearCorporateGovernance');

      const where: ManagementFilter = {
        slug_in: [payload.slug],
      };

      const response = await contentfulGraphQlClient().query({
        query: corporateGovernancePageQuery,
        variables: {
          locale: payload.locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
          where,
        },
        errorPolicy: 'all',
      });
      if (!response?.data?.managementCollection?.items.length) {
        throw new Error('No corporate-governance-page found!');
      }
      commit('setCorporateGovernance', response.data);
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
  async fetchGeneralMeetings(
    { commit },
    payload: { id: string; locale?: LOCALE; skip: number; limit: number }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: generalMeetingCollectionQuery,
      variables: {
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        skip: payload.skip,
        limit: payload.limit,
        order: ['date_DESC'],
      },
    });
    commit('setGeneralMeetings', response.data);
  },
  async fetchVerticalGroupFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: verticalGroupFragmentsQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });

    commit('setFragment', {
      id: payload.id,
      data: response.data.verticalGroupFragment,
    });
  },
  async fetchShortiesFragment(
    { commit },
    payload: { id: string; locale?: LOCALE; skip: number; limit: number }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: shortiesFragmentsQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        limit: payload.limit,
        skip: payload.skip,
      },
    });

    commit('setFragment', {
      id: payload.id,
      data: response.data.shortiesFragment,
    });
  },
  async fetchVideoFragment(
    { commit },
    payload: { id: string; locale?: LOCALE; skip: number; limit: number }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: videoFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        limit: payload.limit,
        skip: payload.skip,
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.videoFragment,
    });
  },
  async fetchContactGroupFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: contactGroupFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.contactGroupFragment,
    });
  },
  async fetchProductDeclarationFragment(
    { commit },
    payload: {
      id: string;
      locale?: LOCALE;
    }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: productDeclarationFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.productDeclarationFragment,
    });
  },
  async fetchReportFilterFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: reportFilterFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.reportFilterFragment,
    });
  },
  async fetchPageTeaserFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: pageTeaserFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });

    commit('setFragment', {
      id: payload.id,
      data: response.data.pageTeaserFragment,
    });
  },
  async fetchContactFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: contactFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.contactFragment,
    });
  },
  async fetchCtaFragment({ commit }, payload: { id: string; locale?: LOCALE }) {
    const response = await contentfulGraphQlClient().query({
      query: ctaFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.ctaFragment,
    });
  },
  async fetchEventOverviewFragment(
    { commit, dispatch },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: eventOverviewFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });

    commit('clearEvents');

    if (response?.data?.eventOverviewFragment) {
      await dispatch('fetchUpcomingEvents', {
        locale: payload.locale,
        skip: 0,
      });
      await dispatch('fetchPastEvents', {
        locale: payload.locale,
        skip: 0,
      });

      commit('setFragment', {
        id: payload.id,
        data: response.data.eventOverviewFragment,
      });
    }
  },
  async fetchEventCollection(
    { commit, dispatch },
    payload: { locale?: LOCALE; category: EventCategory | null }
  ): Promise<void> {
    const { locale, category } = payload;

    commit('clearEvents');

    await dispatch('fetchUpcomingEvents', {
      locale: locale,
      category: category,
      skip: 0,
    });
    await dispatch('fetchPastEvents', {
      locale: locale,
      category: category,
      skip: 0,
    });
  },
  async fetchGeneralMeetingFragment(
    { commit, dispatch },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: generalMeetingFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });

    await dispatch('fetchGeneralMeetings', {
      locale: payload.locale,
      skip: 0,
      limit: 10,
    });

    commit('setFragment', {
      id: payload.id,
      data: response.data.generalMeetingFragment,
    });
  },
  async fetchGenericContentFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: genericContentFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.genericContentFragment,
    });
  },
  async fetchManagementListFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: managementListFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });

    commit('setFragment', {
      id: payload.id,
      data: response.data.managementListFragment,
    });
  },
  async fetchMediaGalleryFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: mediaGalleryFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        skip: 0,
        limit: 20,
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.mediaGalleryFragment,
    });
  },
  async fetchNewsletterSignUpFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: newsletterSignUpFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.newsletterSignUpFragment,
    });
  },
  async fetchTeaserFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: teaserFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.teaserFragment,
    });
  },
  async fetchTeaserListFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: teaserListFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.teaserListFragment,
    });
  },
  async fetchFactListFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: factListFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.factListFragment,
    });
  },
  async fetchStockChartFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: stockChartFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.stockChartFragment,
    });
  },
  async fetchGenericAccordionFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: genericAccordionFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });

    commit('setFragment', {
      id: payload.id,
      data: response.data.genericAccordionFragment,
    });
  },
  async fetchLatestBlogArticlesFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: latestBlogArticlesFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.latestBlogArticlesFragment,
    });
  },
  async fetchLatestNewsFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: latestNewsFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.latestNewsFragment,
    });
    commit(
      'setLatestNewsLink',
      response.data.genericPageCollection.items[0]?.linkedFrom?.linkCollection
        ?.items[0]
    );
  },
  async fetchGenericShelfFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: genericShelfFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        skip: 0,
        limit: 20,
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.genericShelfFragment,
    });
  },
  async fetchSustainabilityStrategyGraphicFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: sustainabilityStrategyGraphicFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.sustainabilityStrategyGraphicFragment,
    });
  },
  async fetchHtmlCodeFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: htmlCodeFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });

    commit('setFragment', {
      id: payload.id,
      data: response.data?.htmlCodeFragment,
    });
  },
  async fetchIFrameFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: iframeFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });

    commit('setFragment', {
      id: payload.id,
      data: response.data?.iFrameFragment,
    });
  },
  async fetchNewsOverviewFragment(
    { commit },
    payload: { id: string; locale?: LOCALE }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: newsOverviewFragmentQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data.newsOverviewFragment,
    });
    commit('setNewsPageBackLink', response.data.newsOverviewFragment);
  },
  async fetchQuote({ commit }, payload: { id: string; locale?: LOCALE }) {
    const response = await contentfulGraphQlClient().query({
      query: quoteQuery,
      variables: {
        id: payload.id,
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });
    commit('setFragment', {
      id: payload.id,
      data: response.data?.quote,
    });
  },
  async fetchEqsNewsCollection({ commit, getters }, payload) {
    try {
      commit('setEqsNewsCollectionLoading', true);

      if (payload.category && !payload.loadMore) {
        commit('clearEqsNewsCollection');
        commit('setEqsNewsCategoryFilter', payload);
        payload.category = getters.eqsNewsCategoryFilter;
      }

      if (!payload.category) {
        commit('clearEqsNewsCategoryFilter');
      }

      const response = await eqsApi
        .getNewsCollection(payload)
        .then((result: EqsNewsCollection) => {
          return result;
        });

      if (!response) {
        throw new Error('No EqsNews found!');
      }

      commit('setEqsNewsCollection', response);
    } catch (e) {
      console.error(e);
    }
  },
  async fetchEqsNewsCategories({ commit }) {
    try {
      const response = eqsApi.getNewsCategories();

      if (!response) {
        throw new Error('No categories found!');
      }
      commit('setEqsNewsCategoryCollection', response);
    } catch (e) {
      console.error(e);
    }
  },
  async fetchNewsPage(
    { commit },
    payload: {
      id: string;
      locale: EqsNewsLanguageType;
      $route: RouteLocation;
      $router: Router;
    }
  ) {
    try {
      if (!payload.id) {
        throw new Error('No EQS-news-id provided!');
      }
      commit('clearNewsPage');

      const response = await eqsApi
        .getNewsItem(payload.id, payload.locale)
        .then((result) => {
          return result;
        });

      if (!response) {
        throw new Error('No EQS-news-page found!');
      }
      commit('setNewsPage', response);
    } catch (e) {
      console.error(e);
      return redirectTo404(payload.locale, payload.$route, payload.$router);
    }
  },
};
