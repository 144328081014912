<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <AppHeader @headerMenuOpen="headerMenuOpenHandler" />
  <main
    class="content"
    :class="{
      'content--no-pt': isNavbarTransparent,
      'content--hidden': isHeaderMenuOpen,
    }"
  >
    <router-view v-if="isRouterReady" v-slot="{ Component, route }">
      <transition
        :name="transitionName"
        v-on:enter="enter"
        v-on:after-enter="afterEnter"
      >
        <component
          :class="{ 'transition-no-pt': isNavbarTransparent }"
          v-if="isRouterReady"
          :is="Component"
          :key="route.path"
        ></component>
      </transition>
    </router-view>
  </main>
  <AppFooter v-if="!isAnimating"></AppFooter>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import AppHeader from '@/containers/AppHeader.vue';
import AppFooter from '@/containers/AppFooter.vue';
import { getLanguageFromLocale } from '@/helpers/i18n';
import { generateMetaLinks } from '@/helpers/metainfo';
import { useMeta } from 'vue-meta';
import { DKLink } from '@/models/DKLink';
import { DKMetaLinkInfo } from '@/models/DKMetaLinkInfo';
import { scrollTo } from '@/helpers/scrollTo';
import { FooterStatus } from '@/store/app/models/FooterStatus';
import { useMq } from 'vue3-mq';
import { RouteRecordName } from 'vue-router';

@Component({
  components: {
    AppHeader,
    AppFooter,
  },
})
export default class App extends Vue {
  transitionName = 'none';
  isAnimating = false;
  isHeaderMenuOpen = false;
  footer: FooterStatus = FooterStatus.GROUP_FOOTER;

  get metaInfo(): DKMetaLinkInfo {
    const link = this.currentLink as any;
    return {
      meta: (this.$metaInfoRef as any).meta,
      locale: this.$i18n.locale,
      links: {
        en: link?.enLink || link?.link,
        de: link?.deLink || link?.link,
      },
      $route: this.$route,
    };
  }

  get isRouterReady(): boolean {
    return this.$store.state.navigation.ready;
  }

  get locale(): string {
    return this.$store.getters['locale'];
  }

  get routeKey(): string {
    return this.$route.path;
  }

  get currentLink(): DKLink {
    return this.$store.getters['navigation/currentLink'];
  }

  get isHomePage(): boolean {
    return this.$router.currentRoute.value.name === 'home-page';
  }

  get isDesktop(): boolean {
    return this.$isDesktop(this.$mq);
  }

  get isNavbarTransparent(): boolean {
    return !!this.$store.getters['navigation/navbarTransparentElement'];
  }

  created() {
    this.$metaInfoRef = useMeta({});
    this.$mq = useMq();
    this.$i18n.locale = this.locale;
    this.footer = this.$route.meta.defaultFooter as FooterStatus;
  }

  mounted() {
    this.$store.dispatch('fetchVerticalOverviews', {
      locale: this.$i18n.locale,
    });

    this.$store.dispatch('navigation/fetchNavigationItems', {
      locale: this.$i18n.locale,
      $route: this.$route,
      $router: this.$router,
    });

    this.$nextTick(() => {
      // Prevent initial page transition
      setTimeout(() => {
        this.transitionName = 'fade';
      }, 1500);
    });

    setTimeout(() => {
      generateMetaLinks(this.metaInfo);
    });

    this.$watch(
      () => this.locale,
      async (locale: string) => {
        const currentLanguage = getLanguageFromLocale(locale);
        const currentLink = this.currentLink;

        this.$store.dispatch('fetchVerticalOverviews', { locale });

        await this.$store.dispatch('navigation/fetchNavigationItems', {
          locale: locale,
          $route: this.$route,
          $router: this.$router,
        });

        this.$i18n.locale = locale;

        if (currentLink) {
          const name = currentLanguage === 'de' ? 'deLink' : 'enLink';
          this.$router.push({
            path: currentLink[name],
            params: { locale: currentLanguage },
          });
        } else if (this.$route.name !== 'news-page') {
          this.$router.push({
            name: this.$route.name as RouteRecordName,
            params: { locale: currentLanguage },
          });
        }
      }
    );

    this.$watch(
      () => this.$route,
      () => {
        this.$store.commit('clearFooterGroup');
        generateMetaLinks(this.metaInfo);
      }
    );

    this.$watch(
      () => this.$route.hash,
      () => {
        setTimeout(() => {
          if (!this.isAnimating) {
            this.scrollToHash();
          }
        });
      }
    );
  }

  serverPrefetch() {
    return Promise.all([
      this.$store.dispatch('fetchVerticalOverviews', {
        locale: this.$i18n.locale,
      }),
      this.$store
        .dispatch('navigation/fetchNavigationItems', {
          locale: this.$i18n.locale,
          $route: this.$route,
          $router: this.$router,
        })
        .then(() => generateMetaLinks(this.metaInfo)),
    ]);
  }

  enter() {
    this.isAnimating = true;
  }

  afterEnter() {
    this.footer = this.$route.meta.defaultFooter as FooterStatus;
    this.isAnimating = false;
    this.scrollToHash();
  }

  headerMenuOpenHandler(value: boolean) {
    const delay = value ? 0 : 300;

    setTimeout(() => {
      this.isHeaderMenuOpen = value;
    }, delay);
  }

  scrollToHash() {
    const { avoidScroll } = this.$route.params;
    if (!(typeof avoidScroll === 'string' && JSON.parse(avoidScroll))) {
      scrollTo(this.$route.hash);
    }
  }
}
</script>

<style scoped lang="postcss">
.content {
  @apply relative;
  @apply mb-auto;
  @apply bg-white;
  @apply pt-main-navbar;

  &.content--no-pt {
    @apply pt-0;
  }

  &.content--hidden {
    @apply z-0;
  }
}

.fade-enter-active {
  @apply absolute;
  @apply top-main-navbar;
  @apply left-0;
  @apply z-10;
  @apply w-full;

  transition: 1s ease;
  transform-origin: 0 0;
  overflow: hidden;

  & :deep(.dk-home__hero--hero),
  & :deep(.dk-page-header--hero),
  & :deep(.dk-page-header--hero .dk-page-header-inner),
  & :deep(.dk-page-header--big-hero),
  & :deep(.dk-page-header--big-hero .dk-page-header-inner) {
    transition: 0.5s ease-in;
    transform-origin: 0 0;
    overflow: hidden;
  }

  & :deep(.dk-page-header--hero .background-media-asset__img),
  & :deep(.dk-page-header--hero .background-media-asset__video),
  & :deep(.dk-page-header--big-hero .background-media-asset__img),
  & :deep(.dk-page-header--big-hero .background-media-asset__video) {
    transition: 1s ease-in;
    transform-origin: 0 0;
    overflow: hidden;
  }
}

.fade-leave-active {
  transition: 0.5s ease;
  transform-origin: 0 0;
  overflow: hidden;

  & :deep(.dk-home__hero--hero),
  & :deep(.dk-page-header--hero),
  & :deep(.dk-page-header--hero .dk-page-header-inner),
  & :deep(.dk-page-header--big-hero),
  & :deep(.dk-page-header--big-hero .dk-page-header-inner) {
    transition: 0.5s ease-out;
    transform-origin: 0 0;
    overflow: hidden;
  }
}

.fade-enter-from {
  & :deep(.dk-page-header--hero .background-media-asset),
  & :deep(.dk-page-header--big-hero .background-media-asset__video),
  & :deep(.dk-page-header--big-hero .background-media-asset__img) {
    transform: scale(1.0125);
  }

  & :deep(.dk-page-header--hero .dk-page-header-inner),
  & :deep(.dk-page-header--big-hero .dk-page-header-inner) {
    transform: translateY(2rem);
  }
}

.fade-leave-to {
  & :deep(.dk-page-header--hero .dk-page-header-inner),
  & :deep(.dk-page-header--big-hero .dk-page-header-inner) {
    transform: translateY(-1rem);
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.transition-no-pt.fade-enter-active {
  @apply top-0;
}
</style>
