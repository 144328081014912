import {
  CareerInsightPageBody as CountryCareerInsightPageBody,
  ManagementBiography as CountryManagementBiography,
  NewsDetailPageBody as CountryNewsDetailPageBody,
  ShortySegmentBody as CountryShortySegmentBody,
} from '../../../types/contentful-api';

import { VideoFactory } from '@/models/factories/VideoFactory';
import { ExternalVideoFactory } from '@/models/factories/ExternalVideoFactory';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { DKRichText } from '@/models/DKRichText';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKVideo } from '@/models/DKVideo';
import { DKExternalVideo } from '@/models/DKExternalVideo';
import { DKAsset } from '@/models/DKAsset';

import { BLOCKS } from '@contentful/rich-text-types';

export type RichText =
  | CountryCareerInsightPageBody
  | CountryManagementBiography
  | CountryNewsDetailPageBody
  | CountryShortySegmentBody;

export class RichTextFactory extends GenericFactory<RichText, DKRichText> {
  contentType = 'RichText';
  requiredProperties = ['json'];

  private createJsonItem(item: any, file: DKAsset | DKVideo | DKExternalVideo) {
    return {
      ...item,
      data: {
        ...item.data,
        target: {
          ...item.data.target,
          fields: {
            file,
          },
        },
      },
    };
  }

  protected map(source: RichText): DKRichText {
    let json: any;
    let asset: DKAsset | undefined;
    let video: DKVideo | undefined;
    let externalVideo: DKExternalVideo | undefined;

    if (source.links) {
      const assetsBlock = source.links.assets?.block;
      const entriesBlock = source.links.entries?.block;

      json = {
        ...source.json,
        content: [...source.json.content].map((item) => {
          if (item.nodeType === BLOCKS.EMBEDDED_ASSET && assetsBlock) {
            const id = item.data.target.sys.id;
            const file = assetsBlock.find((asset) => asset?.sys.id === id);

            if (file?.__typename === 'Asset') {
              const assetFactory = new AssetFactory(this.locale);
              try {
                asset = assetFactory.create(file);
                item = this.createJsonItem(item, asset);
              } catch (e: unknown) {
                if (e instanceof PropertyRequiredError) {
                  console.warn('Could not generate Asset');
                  console.warn(e.message);
                } else {
                  throw e;
                }
              }
            }
          }

          if (item.nodeType === BLOCKS.EMBEDDED_ENTRY && entriesBlock) {
            const id = item.data.target.sys.id;
            const file: any = entriesBlock.find(
              (entry) => entry?.sys.id === id
            );

            if (file.__typename === 'Video') {
              const videoFactory = new VideoFactory(this.locale);
              try {
                video = videoFactory.create(file);
                item = this.createJsonItem(item, video);
              } catch (e: unknown) {
                if (e instanceof PropertyRequiredError) {
                  console.warn('Could not generate Video');
                  console.warn(e.message);
                } else {
                  throw e;
                }
              }
            }

            if (file.__typename === 'ExternalVideo') {
              const externalVideoFactory = new ExternalVideoFactory(
                this.locale
              );
              try {
                externalVideo = externalVideoFactory.create(file);
                item = this.createJsonItem(item, externalVideo);
              } catch (e: unknown) {
                if (e instanceof PropertyRequiredError) {
                  console.warn('Could not generate ExternalVideo');
                  console.warn(e.message);
                } else {
                  throw e;
                }
              }
            }
          }

          return item;
        }),
      };
    }

    return Object.assign({} as DKRichText, {
      ...source,
      json,
    });
  }
}
