import {
  Asset,
  GenericContentFragment,
  Link,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKGenericContentFragment } from '@/models/DKGenericContentFragment';
import { DKAsset } from '@/models/DKAsset';
import { DKLink } from '@/models/DKLink';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { LinkFactory } from '@/models/factories/LinkFactory';

export class GenericContentFragmentFactory extends GenericFactory<
  GenericContentFragment,
  DKGenericContentFragment
> {
  contentType = 'GenericContentFragment';
  requiredProperties = ['shortTitle', 'body'];

  protected map(source: GenericContentFragment): DKGenericContentFragment {
    const downloads: DKAsset[] = [];
    const links: DKLink[] = [];

    if (source.downloadsCollection?.items) {
      const items: Asset[] = source.downloadsCollection.items as Asset[];
      const assetFactory = new AssetFactory(this.locale);

      items.forEach((download: Asset) => {
        try {
          downloads.push(assetFactory.create(download));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Download');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.linksCollection?.items) {
      const items: Link[] = source.linksCollection.items as Link[];
      const linkFactory = new LinkFactory(this.locale);

      items.forEach((link: Link) => {
        try {
          links.push(linkFactory.create(link));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Link');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKGenericContentFragment, {
      ...source,
      downloads,
      links,
    });
  }
}
