import { MutationTree } from 'vuex';
import { ContentState } from '@/store/content/index';
import { filterDuplicateByIdFn } from '@/helpers/filter';
import {
  Event,
  GenericPageContentFragmentsItem,
  GenericShelfFragment,
  SearchTermSuggestion,
} from '../../../types/contentful-api';
import { formatHash } from '@/helpers/format-hash';
import { notEmpty } from '@/helpers/not-empty';
import { DKEventStatus } from '@/models/DKEventOverview';
import { EventCategory } from '@/models/EventCategory';

export const mutations: MutationTree<ContentState> = {
  setHomePage: (state: ContentState, payload) => {
    state.homePage.loading = false;
    state.homePage.data = payload.homePage;
  },
  setHomeSearchTerms: (state: ContentState, payload) => {
    const searchTermSuggestions: SearchTermSuggestion[] =
      payload.searchTermSuggestionCollection?.items || [];
    state.homeSearchTerms = searchTermSuggestions
      .filter(notEmpty)
      .map((item) => item.label)
      .filter(notEmpty);
  },
  setGenericPage: (state: ContentState, payload) => {
    state.genericPage.loading = false;
    state.genericPage.data = payload.genericPage;
  },
  clearGenericPage: (state: ContentState) => {
    state.genericPage.loading = false;
    state.genericPage.data = null;
  },
  setReusablePage: (state: ContentState, payload) => {
    state.reusablePage.loading = false;
    state.reusablePage.data = payload.reusablePage;
  },
  clearReusablePage: (state: ContentState) => {
    state.reusablePage.loading = false;
    state.reusablePage.data = null;
  },
  setNewsDetailPage: (state: ContentState, payload) => {
    state.newsDetailPage.loading = false;
    state.newsDetailPage.data = payload.newsDetailPage;
  },
  clearNewsDetailPage: (state: ContentState) => {
    state.newsDetailPage.loading = false;
    state.newsDetailPage.data = null;
  },
  clearCareerInsightPageId: (state: ContentState) => {
    state.careerInsightPageId.id = '';
  },
  setCareerInsightPageId: (state: ContentState, payload) => {
    state.careerInsightPageId.loading = false;
    state.careerInsightPageId.id = payload;
  },
  clearCareerInsightPage: (state: ContentState) => {
    state.careerInsightPage.data = null;
  },
  setCareerInsightPage: (state: ContentState, payload) => {
    state.careerInsightPage.loading = false;
    if (payload.genericPageCollection.items.length > 0) {
      // back link
      const genericPage = payload.genericPageCollection.items[0];
      const linkedFrom = genericPage.linkedFrom;
      state.careerInsightPage.backLink =
        linkedFrom.linkCollection.items.length > 0
          ? linkedFrom.linkCollection.items[0]
          : null;
      // back link hash
      const fragments = genericPage.contentFragmentsCollection.items;
      const shelfFragment = fragments.find(
        (fragment: GenericShelfFragment) =>
          fragment.__typename === 'GenericShelfFragment'
      );
      if (shelfFragment) {
        const hash = `#${formatHash(shelfFragment.shortTitle)}`;
        state.careerInsightPage.backLinkHash = hash;
      }
    }
    state.careerInsightPage.data =
      payload.careerInsightPageCollection.items.length > 0
        ? payload.careerInsightPageCollection.items[0]
        : null;
  },
  setCareerInsightPageCollection: (state: ContentState, payload) => {
    state.careerInsightPageCollection.loading = false;
    state.careerInsightPageCollection.data.items =
      payload.careerInsightPageCollection.items;
  },
  clearLegalPage: (state: ContentState) => {
    state.legalPage.data = null;
  },
  setLegalPage: (state: ContentState, payload) => {
    state.legalPage.loading = false;
    state.legalPage.data = payload.legalPage;
  },
  setFormPage: (state: ContentState, payload) => {
    state.formPage.loading = false;
    state.formPage.data = payload.formPage;
  },
  clearUseCasePageId: (state: ContentState) => {
    state.useCasePageId.id = '';
  },
  setUseCasePageId: (state: ContentState, payload) => {
    state.useCasePageId.loading = false;
    state.useCasePageId.id = payload;
  },
  clearUseCasePage: (state: ContentState) => {
    state.useCasePage.data = null;
  },
  setUseCasePage: (state: ContentState, payload) => {
    state.useCasePage.loading = false;
    state.useCasePage.data = payload.useCaseCollection.items[0];
  },
  setUseCasePageCollection: (state: ContentState, payload) => {
    state.useCasePageCollection.loading = false;
    state.useCasePageCollection.data.items = payload.useCaseCollection.items;
  },
  setUpcomingEvents: (state: ContentState, payload) => {
    state.events.loading = false;
    state.events.data.upcoming = filterDuplicateByIdFn<Event>([
      ...state.events.data.upcoming,
      ...payload.eventCollection.items,
    ]);
    state.events.data.totalUpcoming = payload.eventCollection.total;
  },
  setPastEvents: (state: ContentState, payload) => {
    state.events.loading = false;
    state.events.data.past = filterDuplicateByIdFn<Event>([
      ...state.events.data.past,
      ...payload.eventCollection.items,
    ]);
    state.events.data.totalPast = payload.eventCollection.total;
  },
  clearEvents: (state: ContentState) => {
    state.events.loading = false;
    state.events.data.upcoming = [];
    state.events.data.totalUpcoming = 0;
    state.events.data.past = [];
    state.events.data.totalPast = 0;
  },
  setEventToggleStatus: (state: ContentState, payload: DKEventStatus) => {
    state.genericPageFilters.eventToggleStatus = payload;
  },
  setEventCategory: (state: ContentState, payload: EventCategory | null) => {
    state.genericPageFilters.eventCategory = payload;
  },
  setPortfolioToggleStatus: (state: ContentState, payload) => {
    state.genericPageFilters.portfolioToggleStatus = payload
      ? 'services'
      : 'products';
  },
  clearReportCollection: (state: ContentState) => {
    state.reportCollection.data.items = [];
  },
  setReportCollection: (state: ContentState, payload) => {
    state.reportCollection.loading = false;
    state.reportCollection.data.items = filterDuplicateByIdFn<any>([
      ...state.reportCollection.data.items,
      ...payload.reportCollection.items,
    ]);
    state.reportCollection.data.total = payload.reportCollection.total;

    // Extract link for report archive
    const genericPage =
      payload.genericPageCollection.items.length > 0
        ? payload.genericPageCollection.items[0]
        : null;
    const genericPageFragment = genericPage
      ? genericPage.contentFragmentsCollection.items.find(
          (contentFragment: GenericPageContentFragmentsItem) =>
            contentFragment.__typename === 'ReportFilterFragment'
        )
      : null;
    const genericPageLink =
      genericPage && genericPage.linkedFrom.linkCollection.items.length > 0
        ? genericPage.linkedFrom.linkCollection.items[0]
        : null;
    state.reportCollection.data.link = genericPageLink;
    state.reportCollection.data.linkHash = `#${formatHash(
      genericPageFragment.shortTitle
    )}`;
  },
  setReportTopicCollection: (state: ContentState, payload) => {
    state.reportTopicCollection.loading = false;
    state.reportTopicCollection.data = payload.reportTopicCollection.items;
  },
  setPortfolio: (state: ContentState, payload) => {
    state.portfolioCollection.loading = false;
    const portfolioFragment = payload.portfolioFragment;
    state.portfolioCollection.data.productClusters =
      portfolioFragment.productClustersCollection.items;
    state.portfolioCollection.data.services =
      portfolioFragment.servicesCollection.items;
  },
  clearCorporateGovernance: (state: ContentState) => {
    state.corporateGovernancePage.data = null;
  },
  setCorporateGovernance: (state: ContentState, payload) => {
    state.corporateGovernancePage.loading = false;
    if (payload.genericPageCollection.items.length > 0) {
      const linkedFrom = payload.genericPageCollection.items[0].linkedFrom;
      state.corporateGovernancePage.backLink =
        linkedFrom.linkCollection.items.length > 0
          ? linkedFrom.linkCollection.items[0]
          : null;
    }
    state.corporateGovernancePage.data =
      payload.managementCollection.items.length > 0
        ? payload.managementCollection.items[0]
        : null;
  },
  setGeneralMeetings: (state: ContentState, payload) => {
    state.generalMeetings.loading = false;
    state.generalMeetings.data = payload.generalMeetingCollection.items;
  },
  setFragment: (state: ContentState, payload) => {
    state.fragments[payload.id] = payload.data;
  },
  setValuePromiseCollection: (state: ContentState, payload) => {
    state.valuePromiseCollection.loading = false;
    state.valuePromiseCollection.data.items = payload;
  },
  setEqsNewsCollection: (state: ContentState, payload) => {
    state.eqsNewsCollection.loading = false;
    state.eqsNewsCollection.data = payload;
  },
  clearEqsNewsCollection: (state: ContentState) => {
    state.eqsNewsCollection.data.items = [];
    state.eqsNewsCollection.data._eqs.total_news = 0;
    state.eqsNewsCollection.data._eqs.total_pages = 0;
  },
  setEqsNewsCollectionLoading: (state: ContentState, payload) => {
    state.eqsNewsCollection.loading = payload;
  },
  setEqsNewsCategoryCollection: (state: ContentState, payload) => {
    state.eqsNewsItemCategoryCollection.loading = false;
    state.eqsNewsItemCategoryCollection.data = payload;
  },
  setEqsNewsCategoryFilter: (state: ContentState, payload) => {
    if (
      state.eqsNewsCategoryFilter.includes(payload.category[0]) &&
      !payload.urlFilter
    ) {
      state.eqsNewsCategoryFilter = state.eqsNewsCategoryFilter.filter(
        (slug) => slug !== payload.category[0]
      );
    } else {
      state.eqsNewsCategoryFilter = [
        ...state.eqsNewsCategoryFilter,
        ...payload.category,
      ];
    }
  },
  clearEqsNewsCategoryFilter: (state: ContentState) => {
    state.eqsNewsCategoryFilter = [];
  },
  setLatestNewsLink: (state: ContentState, payload) => {
    state.eqsNewsCollection.newsLink = payload;
  },
  setNewsPage: (state: ContentState, payload) => {
    state.newsPage.loading = false;
    state.newsPage.data = payload;
  },
  clearNewsPage: (state: ContentState) => {
    state.newsPage.data = null;
  },
  setNewsPageBackLink: (state: ContentState, payload) => {
    if (payload.linkedFrom.genericPageCollection.items.length > 0) {
      const linkedFrom =
        payload.linkedFrom.genericPageCollection.items[0].linkedFrom;
      state.newsPage.backLink =
        linkedFrom.linkCollection.items.length > 0
          ? linkedFrom.linkCollection.items[0]
          : null;
    }
  },
};
