import { MutationTree } from 'vuex';
import { NavigationState } from '@/store/navigation/index';
import { NavigationTreeItem } from '@/store/navigation/models/NavigationTreeItem';
import { Link, NavigationItem } from '../../../types/contentful-api';
import { NavbarTransparentElement } from '@/models/navigation/NavbarTypes';

export const mutations: MutationTree<NavigationState> = {
  setReady: (state: NavigationState) => {
    state.ready = true;
  },
  setNavigations: (state: NavigationState, payload) => {
    state.navigations.data = payload.items;
  },
  setNavigationItem: (state: NavigationState, payload) => {
    if (!payload) {
      return;
    }
    const navigationItem = state.navigationItems.data.find(
      (currentItem) => currentItem.sys.id === payload.sys.id
    );
    if (!navigationItem) {
      state.navigationItems.data.push(payload);
    }
  },
  setNavigationItems: (state: NavigationState, payload) => {
    state.navigationItems.data = payload.items;
  },
  setLinks: (state: NavigationState, payload) => {
    state.links.data = payload.items.map((item: NavigationItem) => item.link);
  },
  setLink: (state: NavigationState, payload) => {
    if (!payload) {
      return;
    }
    const link = state.links.data.find(
      (currentLink) => currentLink.sys.id === payload.sys.id
    );
    if (!link) {
      state.links.data.push(payload);
    }
  },
  setCurrentLink: (state: NavigationState, payload) => {
    state.currentLink = payload;
  },
  setCurrentLinkBySlug: (state: NavigationState, payload) => {
    state.currentLink =
      state.links.data.find(
        (link: Link) =>
          link?.link &&
          link?.link !== '/' &&
          (payload.endsWith(link.link) || payload.endsWith(`${link.link}/`))
      ) || null;
  },
  setCurrentNavigationTreeItem: (state: NavigationState, payload) => {
    state.currentNavigationTreeItem = payload;
  },
  clearCurrentNavigationTreeItem: (state: NavigationState) => {
    state.currentNavigationTreeItem = null;
  },
  openNavigationItem: (state: NavigationState, id: string) => {
    const navigationItem = state.navigationItems.data.find(
      (currentItem) => currentItem.sys.id == id
    );
    if (navigationItem) {
      navigationItem.expanded = true;
    }
    if (state.currentNavigationTreeItem) {
      state.currentNavigationTreeItem.expanded = true;
    }
  },
  closeNavigationItem: (state: NavigationState, id: string) => {
    const navigationItem = state.navigationItems.data.find(
      (currentItem) => currentItem.sys.id == id
    );
    if (navigationItem) {
      navigationItem.expanded = false;
    }
    if (state.currentNavigationTreeItem) {
      state.currentNavigationTreeItem.expanded = false;
    }
  },
  closeAllNavigationItems: (state: NavigationState) => {
    state.navigationItems.data.forEach(
      (navigationItem) => (navigationItem.expanded = false)
    );
  },
  toggleNavigationItem: (
    state: NavigationState,
    navigationTreeItem: NavigationTreeItem
  ) => {
    state.navigationItems.data.forEach((navigationItem) => {
      if (navigationItem.sys.id === navigationTreeItem.id) {
        navigationItem.expanded = !navigationItem.expanded;
      }
      if (!navigationTreeItem.breadcrumbs.includes(navigationItem.sys.id)) {
        navigationItem.expanded = false;
      }
    });
  },
  openTree: (
    state: NavigationState,
    navigationTreeItem: NavigationTreeItem
  ) => {
    state.navigationItems.data.forEach((navigationItem) => {
      const same = navigationItem.sys.id === navigationTreeItem.id;
      navigationItem.expanded =
        !same && navigationTreeItem.breadcrumbs.includes(navigationItem.sys.id);
    });
  },
  closeTree: (state: NavigationState) => {
    state.currentNavigationTreeItem = null;
    state.locationSwitcherExpanded = false;
    state.navigationItems.data.forEach(
      (navigationItem) => (navigationItem.expanded = false)
    );
  },
  openLocationSwitcher: (state: NavigationState) => {
    state.locationSwitcherExpanded = true;
  },
  closeLocationSwitcher: (state: NavigationState) => {
    state.locationSwitcherExpanded = false;
  },
  toggleLocationSwitcher: (state: NavigationState) => {
    state.locationSwitcherExpanded = !state.locationSwitcherExpanded;
  },
  toggleLanguageSwitcher: (state: NavigationState) => {
    state.languageSwitcherExpanded = !state.languageSwitcherExpanded;
  },
  closeLanguageSwitcher: (state: NavigationState) => {
    state.languageSwitcherExpanded = false;
  },
  toggleSearch: (state: NavigationState) => {
    state.searchExpanded = !state.searchExpanded;
  },
  closeSearch: (state: NavigationState) => {
    state.searchExpanded = false;
  },
  setActiveNavLinkId: (state: NavigationState, id: string) => {
    state.activeNavLinkId = id;
  },
  setNavbarIsHidden(state: NavigationState, value: boolean) {
    state.navbarHidden = value;
  },
  setNavbarTransparentElement(
    state: NavigationState,
    payload: NavbarTransparentElement
  ) {
    state.transparentElement = payload;
  },
};
