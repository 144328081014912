export enum MenuSelectType {
  MAIN_NAVIGATION = 'Main Navigation',
  COUNTRY_MENU_EUROPE = 'Country Menu EUROPE',
  COUNTRY_MENU_ASIA_PACIFIC = 'Country Menu ASIA-PACIFIC',
  COUNTRY_MENU_MIDDLE_EAST = 'Country Menu MIDDLE-EAST',
  COUNTRY_MENU_AMERICAS = 'Country Menu AMERICAS',
  SHORT_LINKS = 'Short Links',
  CONTACT_LINKS = 'Contact Links',
  SUB_FOOTER = 'Sub-Footer',
  SOCIAL_MEDIA = 'Social Media',
}
