<template>
  <header
    :class="[
      'dk-header',
      {
        'dk-header--backdrop': hasBackdrop,
        'dk-header--hidden': isNavbarHidden,
      },
    ]"
    itemscope
    itemtype="http://schema.org/WPHeader"
  >
    <Navbar
      v-show="isNavbarLoaded"
      :type="navbarType"
      :isOpen="isMenuOpen"
      :homeHref="homeHref"
      @clickHome="onClickHome"
      @mouseleave="onLeaveNavbar(currentNavigationTreeItem)"
    >
      <template v-if="isDesktop">
        <NavbarLink
          v-for="navigationTreeItem of navigationTree"
          :key="navigationTreeItem.id"
          :navigationTree="navigationTree"
          :navigationTreeItem="navigationTreeItem"
          @clickNavbarLink="onClickNavbarLink"
          @clickNavbarLinkNavigate="onClickNavbarLinkNavigate"
        ></NavbarLink>
      </template>
      <template #actions>
        <NavbarSearch
          :isExpanded="searchExpanded"
          @toggle="onToggleSearch"
          @close="onCloseSearch"
        ></NavbarSearch>
        <NavbarLanguageSwitcher
          :isExpanded="languageSwitcherExpanded"
          @toggle="onToggleLanguageSwitcher"
          @switchLang="onSwitchLang"
        ></NavbarLanguageSwitcher>
        <NavbarLocationSwitcher
          :isExpanded="locationSwitcherExpanded"
          :locationGroups="locationGroups"
          :contactNavigationTree="contactNavigationTree"
          @clickLocationSwitcher="onClickLocationSwitcher"
          @clickLocationLink="onClickLocationLink"
        ></NavbarLocationSwitcher>
      </template>
      <NavbarHamburger
        v-if="!isDesktop"
        :isOpen="isMenuOpen"
        :navigationTree="navigationTree"
        :locationGroups="mobileLocationGroups"
        :contactNavigationTree="contactNavigationTree"
        @clickHamburger="onClickHamburger"
        @clickNavbarTab="onClickNavbarTab"
        @clickNavbarTabToggle="onClickNavbarTabToggle"
        @switchLang="onSwitchLang"
        @clickContactLink="onClickContactLink"
        @onMobileSearch="onMobileSearch"
      ></NavbarHamburger>
      <template v-slot:brand>
        <DKBrandTag />
      </template>
    </Navbar>
    <Megamenu
      v-if="isDesktop"
      type="secondary"
      :isExpanded="isMegaMenuExpanded"
      :isFull="locationSwitcherExpanded || isMenuOpen"
    ></Megamenu>
  </header>
</template>

<script lang="ts">
import { defineAsyncComponent } from 'vue';
import { Vue, Component } from 'vue-facing-decorator';
import { LinkTarget } from '@/models/LinkTarget';
import {
  NavbarTypes,
  NavbarTransparentElement,
} from '@/models/navigation/NavbarTypes';
import { setCookie } from '@/helpers/cookie';
import { openLink, navigateToAnchor } from '@/helpers/link';
import Navbar from '@/components/navbar/Navbar.vue';
import Megamenu from '@/components/navbar/Megamenu.vue';
import { NavigationTree } from '@/store/navigation/models/NavigationTree';
import { NavigationTreeItem } from '@/store/navigation/models/NavigationTreeItem';
import { MenuSelectType } from '@/store/app/models/MenuSelectType';
import { LocationGroup } from '@/store/navigation/models/LocationGroup';
import { DKBrandTag } from '@dormakaba/dormakaba-components';
import { useMq } from 'vue3-mq';
import { getLanguageFromLocale } from '@/helpers/i18n';

const NavbarLink = defineAsyncComponent(
  () => import('@/components/navbar/NavbarLink.vue')
);

const NavbarSearch = defineAsyncComponent(
  () => import('@/components/navbar/NavbarSearch.vue')
);

const NavbarLanguageSwitcher = defineAsyncComponent(
  () => import('@/components/navbar/NavbarLanguageSwitcher.vue')
);

const NavbarLocationSwitcher = defineAsyncComponent(
  () => import('@/components/navbar/NavbarLocationSwitcher.vue')
);

const NavbarHamburger = defineAsyncComponent(
  () => import('@/components/navbar/NavbarHamburger.vue')
);

@Component({
  components: {
    Navbar,
    NavbarLink,
    DKBrandTag,
    NavbarHamburger,
    NavbarSearch,
    NavbarLanguageSwitcher,
    NavbarLocationSwitcher,
    Megamenu,
  },
  emits: ['headerMenuOpen'],
})
export default class AppHeader extends Vue {
  scrollObserver: null | IntersectionObserver = null;
  isTransparent = false;
  scrollPostion = 0;
  isNavbarHidden = false;
  isNavbarLoaded = false;

  get currentNavigationTreeItem() {
    return this.$store.state.navigation.currentNavigationTreeItem;
  }

  get navigationTree(): NavigationTree {
    return this.$store.getters['navigation/navigationTree'](
      MenuSelectType.MAIN_NAVIGATION
    );
  }

  get navigationTreeItem(): NavigationTreeItem {
    return this.$store.getters['navigation/navigationTreeItem'](
      this.$route.path,
      this.navigationTree
    );
  }

  get searchExpanded(): boolean {
    return this.$store.state.navigation.searchExpanded;
  }

  get locationSwitcherExpanded(): boolean {
    return this.$store.state.navigation.locationSwitcherExpanded;
  }

  get languageSwitcherExpanded(): boolean {
    return this.$store.state.navigation.languageSwitcherExpanded;
  }

  get isMenuOpen(): boolean {
    return this.$store.getters['isMenuOpen'];
  }

  get hasBackdrop(): boolean {
    return this.$store.getters['hasBackdrop'];
  }

  get language(): string {
    return getLanguageFromLocale(this.$i18n.locale).toLowerCase();
  }

  get homeHref(): string {
    return `/${this.language}`;
  }

  get isDesktop(): boolean {
    return this.$isDesktop(this.$mq);
  }

  get navbarType(): NavbarTypes {
    return this.isTransparent && !this.isAppFreezed
      ? NavbarTypes.Transparent
      : NavbarTypes.Secondary;
  }

  get isAppFreezed() {
    return this.isDesktop
      ? this.isMegaMenuExpanded ||
          this.searchExpanded ||
          this.languageSwitcherExpanded
      : this.isMenuOpen;
  }

  get isMegaMenuExpanded() {
    return (
      this.currentNavigationTreeItem?.expanded ||
      this.locationSwitcherExpanded ||
      this.isMenuOpen
    );
  }

  get gradientElement(): NavbarTransparentElement {
    return this.$store.getters['navigation/navbarTransparentElement'];
  }

  created() {
    this.$mq = useMq();
  }

  mounted() {
    if (this.$route.hash) {
      navigateToAnchor(this.$route.hash);
    }

    this.createScrollListener();

    this.$watch(
      () => this.hasBackdrop,
      (hasBackdrop: boolean) => {
        const body = document.getElementsByTagName('body')[0];
        hasBackdrop
          ? body.classList.add('dk-header-open')
          : body.classList.remove('dk-header-open');
      }
    );

    this.$watch(
      () => this.$isDesktop(this.$mq),
      (isDesktop: boolean) => {
        if (!isDesktop) {
          this.$store.commit('closeBackdrop');
          this.$store.commit('closeMenu');
          this.$store.commit('navigation/closeTree');
          this.$store.commit('navigation/closeLanguageSwitcher');
        } else if (this.isMenuOpen) {
          this.onClickHamburger();
        }
      }
    );

    this.$watch(
      () => this.gradientElement,
      (val: HTMLElement | null) => {
        this.isTransparent = !!this.gradientElement;
        this.isNavbarLoaded = true;

        if (val) {
          this.createScrollObserver(val);
        }
      }
    );

    this.$watch(
      () => this.$route,
      (newRoute, oldRoute) => {
        if (newRoute.path !== oldRoute.path) {
          this.destroyScrollObserver();
        }
      }
    );

    this.$watch(
      () => this.isAppFreezed,
      (val: boolean) => {
        this.$emit('headerMenuOpen', val);
      }
    );
  }

  beforeUnmount() {
    document.removeEventListener('scroll', this.scrollListener);
    this.destroyScrollObserver();
  }

  createScrollListener() {
    this.scrollPostion = window.scrollY;

    document.addEventListener('scroll', this.scrollListener);
  }

  scrollListener() {
    const newScrollPosition = window.scrollY;

    this.isNavbarHidden =
      this.scrollPostion <= newScrollPosition &&
      newScrollPosition > 0 &&
      !this.isMenuOpen;

    this.$store.commit('navigation/setNavbarIsHidden', this.isNavbarHidden);
    this.$store.commit('navigation/closeLanguageSwitcher');

    this.scrollPostion = newScrollPosition;
  }

  createScrollObserver(observerElement: HTMLElement) {
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        this.isTransparent = entry.isIntersecting;
      });
    };

    this.scrollObserver = new IntersectionObserver(callback, {
      threshold: 0.25,
    });
    this.scrollObserver.observe(observerElement);
  }

  destroyScrollObserver() {
    this.gradientElement &&
      this.scrollObserver?.unobserve(this.gradientElement);
  }

  onLeaveNavbar(navigationTreeItem: NavigationTreeItem) {
    if (!navigationTreeItem) return;
    this.$store.commit(
      'navigation/setCurrentNavigationTreeItem',
      navigationTreeItem
    );
    this.$store.commit('navigation/closeNavigationItem', navigationTreeItem.id);
    if (!this.$store.getters['navigation/isLocationSwitcherOpen']()) {
      this.$store.commit('closeBackdrop');
    }
  }

  onClickNavbarLink(payload: { id: string; path: string; target: LinkTarget }) {
    this.$store.commit('navigation/closeAllNavigationItems');
    this.$store.commit('navigation/setCurrentNavigationTreeItem', payload);
    this.$store.commit('navigation/openNavigationItem', payload.id);
    this.$store.commit('navigation/closeLanguageSwitcher');
    this.$store.commit('navigation/closeLocationSwitcher');
    this.$store.commit('navigation/closeSearch');
    this.$store.commit('openBackdrop');
  }

  onClickNavbarLinkNavigate(payload: {
    id: string;
    path: string;
    target: LinkTarget;
  }) {
    this.$store.commit('navigation/closeAllNavigationItems');
    this.$store.commit('closeBackdrop');
    this.$store.commit('navigation/closeNavigationItem', payload.id);
    this.$store.commit('navigation/closeLanguageSwitcher');
    this.$store.commit('navigation/closeLocationSwitcher');

    openLink(payload.path, { target: payload.target });
  }

  onToggleLanguageSwitcher() {
    this.onLeaveNavbar(this.currentNavigationTreeItem);
    this.$store.commit('navigation/toggleLanguageSwitcher');
    this.$store.commit('navigation/closeLocationSwitcher');
    this.$store.commit('navigation/closeSearch');
    this.$store.commit('closeBackdrop');
  }

  onClickHome() {
    this.$router.push({ name: 'home-page' });
    this.$store.commit('navigation/closeLanguageSwitcher');
    this.$store.commit('navigation/closeLocationSwitcher');
    this.$store.commit('navigation/closeSearch');
    this.$store.commit('closeBackdrop');
    this.$store.commit('navigation/closeAllNavigationItems');
    setTimeout(() => this.$store.commit('closeMenu'), 400);
  }

  onClickHamburger() {
    this.$store.commit('navigation/clearCurrentNavigationTreeItem');
    this.$store.commit('toggleMenu');
    this.$store.commit('toggleBackdrop');
  }

  async onClickNavbarTab(navigationTreeItem: NavigationTreeItem) {
    scrollTo({ top: 0 });
    setTimeout(() => {
      this.$store.commit('closeMenu');

      setTimeout(
        () =>
          this.onClickNavbarLinkNavigate({
            id: navigationTreeItem.id,
            path: navigationTreeItem.path || '',
            target: navigationTreeItem.target,
          }),
        50
      );
    }, 400);
  }

  onClickNavbarTabToggle(navigationTreeItem: NavigationTreeItem) {
    this.$store.commit('navigation/toggleNavigationItem', navigationTreeItem);
    this.$store.commit(
      'navigation/setCurrentNavigationTreeItem',
      navigationTreeItem
    );
  }

  onToggleSearch() {
    this.onLeaveNavbar(this.currentNavigationTreeItem);
    this.$store.commit('navigation/closeLanguageSwitcher');
    this.$store.commit('navigation/closeLocationSwitcher');
    this.$store.commit('closeBackdrop');

    const searchInput = document.getElementById('navbar-search');

    setTimeout(
      async () => [
        await this.$store.commit('navigation/toggleSearch'),
        searchInput && searchInput.focus(),
      ],
      200
    );
  }

  onCloseSearch() {
    this.$store.commit('navigation/closeSearch');
    this.$store.commit('closeBackdrop');
  }

  onMobileSearch() {
    setTimeout(() => this.$store.commit('closeMenu'), 400);
    this.$store.commit('closeBackdrop');
  }

  onClickContactLink(navigationTreeItem: NavigationTreeItem) {
    this.$store.commit('closeBackdrop');
    this.$store.commit('navigation/closeLocationSwitcher');
    setTimeout(() => this.$store.commit('closeMenu'), 400);
    openLink(navigationTreeItem.path, {
      target: navigationTreeItem.target,
    });
  }

  onClickLocationLink(navigationTreeItem: NavigationTreeItem) {
    openLink(navigationTreeItem.path, {
      target: navigationTreeItem.target,
    });
  }

  onSwitchLang(locale: string) {
    this.$store.commit('closeBackdrop');
    setTimeout(() => this.$store.commit('closeMenu'), 400);
    this.$store.commit('navigation/closeLanguageSwitcher');
    this.$store.commit('navigation/closeLocationSwitcher');
    this.$store.commit('setLocale', { locale });
    this.$store.commit('blog/setLocalizedArticles', { locale });
    setCookie('locale', locale);
  }

  onClickLocationSwitcher() {
    this.onLeaveNavbar(this.currentNavigationTreeItem);
    this.$store.commit('navigation/toggleLocationSwitcher');
    this.$store.commit('navigation/closeLanguageSwitcher');
    this.$store.commit('navigation/closeSearch');
    this.$store.commit('toggleBackdrop');
  }

  get contactNavigationTree(): NavigationTree {
    return this.$store.getters['navigation/navigationTree'](
      MenuSelectType.CONTACT_LINKS
    );
  }

  get locationGroups(): LocationGroup[] {
    const europeNavigationTree = this.$store.getters[
      'navigation/navigationTree'
    ](MenuSelectType.COUNTRY_MENU_EUROPE);
    const halfNavigationTree = Math.ceil(europeNavigationTree.length / 2);
    const locations = [
      {
        locationName: 'group',
        order: 1,
        navigationTree: this.$store.getters['navigation/navigationTree'](
          MenuSelectType.MAIN_NAVIGATION
        ),
      },
      {
        locationName: 'america',
        order: 2,
        navigationTree: this.$store.getters['navigation/navigationTree'](
          MenuSelectType.COUNTRY_MENU_AMERICAS
        ),
      },
      {
        locationName: 'europe',
        order: 3,
        navigationTree: europeNavigationTree.slice(0, halfNavigationTree),
      },
      {
        locationName: '',
        order: 4,
        navigationTree: europeNavigationTree.slice(halfNavigationTree),
      },
      {
        locationName: 'middleEast',
        order: 5,
        navigationTree: this.$store.getters['navigation/navigationTree'](
          MenuSelectType.COUNTRY_MENU_MIDDLE_EAST
        ),
      },
      {
        locationName: 'asia',
        order: 6,
        navigationTree: this.$store.getters['navigation/navigationTree'](
          MenuSelectType.COUNTRY_MENU_ASIA_PACIFIC
        ),
      },
    ];

    return locations.sort((a, b) => a.order - b.order);
  }

  get mobileLocationGroups(): LocationGroup[] {
    const locations = [
      {
        locationName: 'group',
        order: 1,
        navigationTree: this.$store.getters['navigation/navigationTree'](
          MenuSelectType.MAIN_NAVIGATION
        ),
      },
      {
        locationName: 'america',
        order: 2,
        navigationTree: this.$store.getters['navigation/navigationTree'](
          MenuSelectType.COUNTRY_MENU_AMERICAS
        ),
      },
      {
        locationName: 'europe',
        order: 3,
        navigationTree: this.$store.getters['navigation/navigationTree'](
          MenuSelectType.COUNTRY_MENU_EUROPE
        ),
      },
      {
        locationName: 'middleEast',
        order: 4,
        navigationTree: this.$store.getters['navigation/navigationTree'](
          MenuSelectType.COUNTRY_MENU_MIDDLE_EAST
        ),
      },
      {
        locationName: 'asia',
        order: 5,
        navigationTree: this.$store.getters['navigation/navigationTree'](
          MenuSelectType.COUNTRY_MENU_ASIA_PACIFIC
        ),
      },
    ];

    return locations.sort((a, b) => a.order - b.order);
  }
}
</script>

<style lang="postcss" scoped>
:deep(.dk-hamburger) {
  @apply p-0;
}
</style>
