import {
  EqsNewsCollection,
  EqsCategorySlug,
  EqsNewsItem,
  EqsNewsItemCategory,
  EqsNewsLanguage,
  EqsNewsLanguageType,
  EqsNewsOptions,
} from '../../../types/eqs-types';
import mockData from './eqs-mock-data.json';
import { getEnv } from '@/env';

export class EqsApi {
  constructor(private readonly companyToken: string) {
    this.companyToken = companyToken;
  }

  /**
   * Get EQS news feed data
   * @param options
   */
  public async getNewsCollection(
    options?: EqsNewsOptions
  ): Promise<EqsNewsCollection> {
    if (getEnv('EQS_MOCK_DATA') === 'true') {
      return mockData as EqsNewsCollection;
    }

    const feedUrl = getEnv('EQS_FEED_URL');
    const queryParams = this.buildQueryParams(options);
    const language = options?.language || EqsNewsLanguage.ENGLISH;
    const baseFeedUrl = this.getUrlWithLanguage(feedUrl, language);
    const response = await fetch(
      `${baseFeedUrl}?companyToken=${this.companyToken}${queryParams}`
    );
    return await response.json();
  }

  /**
   * Get Single EQS news item
   * @param id
   * @param language
   */
  public async getNewsItem(
    id: string,
    language: EqsNewsLanguageType
  ): Promise<EqsNewsItem | undefined> {
    const detailUrl = getEnv('EQS_DETAIL_URL');
    const baseDetailUrl = this.getUrlWithLanguage(detailUrl, language);
    const response = await fetch(`${baseDetailUrl}?newsId=${id}`);

    return await response.json();
  }

  /**
   * Get EQS news categories
   */
  public getNewsCategories(): EqsNewsItemCategory[] {
    return [
      {
        name: 'Ad hoc',
        slug: EqsCategorySlug.ADHOC,
      },
      {
        name: 'Innovation & Digitization',
        slug: EqsCategorySlug.INNOVATION,
      },
      {
        name: 'Products',
        slug: EqsCategorySlug.PRODUCTS,
      },
      {
        name: 'Sustainability',
        slug: EqsCategorySlug.SUSTAINABILITY,
      },
      {
        name: 'Trade Fairs',
        slug: EqsCategorySlug.TRADE_FAIRS,
      },
      {
        name: 'dormakaba News',
        slug: EqsCategorySlug.DORMAKABA_NEWS,
      },
    ];
  }

  /**
   * Build query string from options, if no options are provided an empty string is returned.
   * Category is joined with a "." (dot) for AND query. OR queries are joined with a ";".
   * @param options
   * @private
   */
  private buildQueryParams(options?: EqsNewsOptions): string {
    const hasOnlyLanguageOption =
      options?.language !== undefined && Object.keys(options).length === 1;

    if (!options || hasOnlyLanguageOption) {
      return '';
    }
    const queryParams = new URLSearchParams();
    if (options?.type) {
      queryParams.set('type', options.type);
    }
    if (options?.category) {
      queryParams.set(
        'category',
        options.category.join('.').replaceAll('-', '_')
      );
    }
    if (options?.start !== undefined) {
      queryParams.set('start', String(options.start));
    }
    if (options?.limit !== undefined) {
      queryParams.set('limit', String(options.limit));
    }
    return `&${queryParams.toString()}`;
  }

  /**
   * Replace language placeholder in url
   * @param url
   * @param language
   * @private
   */
  private getUrlWithLanguage(
    url: string,
    language: EqsNewsLanguageType
  ): string {
    return url.replace('{language}', language);
  }
}
